<template>
  <v-flex class="view-space-form">
    <h1 hidden>{{ $t('space.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap class="form-search--main-wrap-v2">
          <v-flex class="form-search--actions-zone-v2">
            <button-primary
              data-test="space--btn-add"
              id="space--btn-add"
              class="space-form--btn-add"
              :loading="working"
              :text="$t('space.form.add')"
              @click="openCreateSpaceModal"
            ></button-primary>
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && spaces.length === 0" :text="$t('space.no_space_found')"></alert-warning>
      <card v-if="dataReady && spaces.length > 0" icon="fal fa-store" :title="`${$t('space.form.data_section.title')}`">
        <template slot="headerZoneRight">
          <label-text class="text-sm-center" :label="$t('space.form.data_section.spaces_count')" :value="listItemCount || '0'"></label-text>
        </template>
        <template slot="content">
          <spaces-table :items="spaces"></spaces-table>
        </template>
      </card>
    </v-flex>
    <modal-action :show-modal="showCreateSpaceModal"
      ><create-space-action v-if="showCreateSpaceModal" @close="closeCreateSpaceModal" @created="createSpaceSuccess"></create-space-action
    ></modal-action>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import SpacesTable from './SpacesTable';
import CreateSpaceAction from '../actions/CreateSpaceAction';
import AlertWarning from '@/components/system/Alerts/AlertWarning';
import _ from 'lodash';
import Vue from 'vue';
import { ignoreCaseAndAccentsRegex } from '@/utils/string-utils';

export default {
  name: 'view-space-form',
  mixins: [actionErrorTrackable, security],
  components: { AlertWarning, SpacesTable, CreateSpaceAction },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'space/clearListSpaces' });
  },
  data() {
    return {
      page: 1,
      dataReady: false,
      keyword: '',
      searched: '',
      showCreateSpaceModal: false,
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;

    if (this.routeHasQueryParams()) {
      this.page = 1;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }

    this.routeHasQueryParams() ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  watch: {},
  computed: {
    ...mapGetters('space', ['spaces', 'listKeyword', 'listPageNumber', 'listItemCount']),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.$validator.reset();
    },
    routeHasQueryParams() {
      return this.$route.query.keyword;
    },
    openCreateSpaceModal() {
      this.showCreateSpaceModal = true;
    },
    closeCreateSpaceModal() {
      this.showCreateSpaceModal = false;
    },
    buildPayloadQueryString() {
      const payload = {};
      if (this.keyword) {
        payload.keyword = this.keyword;
      }

      return payload;
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        this.$router.replace({ query: this.buildPayloadQueryString() });
        await this.clearAllError();

        try {
          this.dataReady = false;
          const search = this.buildPayloadQueryString();
          search.page = page;
          await this.executeAction({ action: 'space/listSpacesWithPartner' }, search);
        } finally {
          this.dataReady = true;
        }
      }
    },
    async listSpacesWithPartner() {
      await this.executeAction(
        {
          action: 'space/listSpacesWithPartner',
        },
        {
          page: this.listPageNumber,
          keyword: this.listKeyword,
        },
      );
    },
    async createSpaceSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'space.create_action.success' });
      this.closeCreateSpaceModal();
      await this.listSpacesWithPartner();
    },
  },
};
</script>
