<template>
  <card icon="" :title="`${$t('card.limits_and_balances.title')}`" class="card-balances-section">
    <template slot="headerZoneRight">
      <v-layout class="balances-header-zone">
        <label-text :label="$t(`card.limits_and_balances.open_wallet_balance`)" :value="card.open_wallet_balance | currency"></label-text>
        <label-text :label="$t(`card.limits_and_balances.custom_wallet_balance`)" :value="card.other_wallets_balance | currency"></label-text>
        <label-text :label="$t(`card.limits_and_balances.card_balance`)" :value="card.card_balance | currency"></label-text>
      </v-layout>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <v-layout class="balances-header-zone">
        <label-text
          :label="$t(`card.limits_and_balances.open_wallet_limit`)"
          :value="card.balance.limits.open_wallet_balance.max.value | currency"
        ></label-text>
        <label-text :label="$t(`card.limits_and_balances.total_limit`)" :value="card.balance.limits.card_balance.max.value | currency"></label-text>
      </v-layout>
      <div v-if="showUpdateCardMaxBalanceFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <v-layout class="balances-header-zone">
        <label-text v-if="showUpdateCardMaxBalanceFeature()" :label="`${$t('card.limits_and_balances.action')}`" value="3">
          <link-secondary-action slot="labelTextLink" @click="openModalUpdateCardMaxBalance">
            {{ $t('card.limits_and_balances.button_update_card_max_balance') }}
          </link-secondary-action>
        </label-text>
      </v-layout>
    </template>
    <template slot="content">
      <card-wallets :items="sortedWalletsOpenWalletFirst" :card="card" @refresh_wallets="sortWallets()" />

      <p
        role="button"
        aria-label="toggle list of empty wallets"
        :class="{ 'show-empty-wallets': true, 'is-disabled': numberOfEmptyWallets === 0 }"
        @click="toggleEmptyWalletsSection"
      >
        {{ toggleEmptyWalletsText }}
      </p>

      <card-wallets
        v-show="showEmptyWalletsSection"
        aria-label="list of empty wallets"
        :items="emptyWalletsAlphabetical"
        :card="card"
        @refresh_wallets="sortWallets()"
      />
    </template>
  </card>
</template>

<script>
import _ from 'lodash';
import { actionErrorTrackable, security } from '@/mixins';
import CardWallets from './CardWallets';

export default {
  name: 'limits-and-balances',
  mixins: [actionErrorTrackable, security],
  components: { CardWallets },
  props: {
    card: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showEmptyWalletsSection: false,
      sortedWalletsOpenWalletFirst: [],
      emptyWalletsAlphabetical: [],
    };
  },
  mounted() {
    this.sortWallets();
  },
  watch: {
    card() {
      if (this.card) {
        this.sortWallets();
      } else {
        this.showEmptyWalletsSection = false;
        this.sortedWalletsOpenWalletFirst = [];
        this.emptyWalletsAlphabetical = [];
      }
    },
    numberOfEmptyWallets(val) {
      if (val === 0) {
        this.showEmptyWalletsSection = false;
      }
    },
  },
  computed: {
    walletRulesHeaders() {
      return [
        {
          text: this.$t('card.limits_and_balances.merchant_id'),
          sortable: false,
          value: 'merchant_id',
        },
        {
          text: this.$t('card.limits_and_balances.merchant_name'),
          sortable: false,
          value: 'merchant_name',
        },
      ];
    },
    numberOfEmptyWallets() {
      return this.emptyWalletsAlphabetical.length;
    },
    toggleEmptyWalletsText() {
      return this.showEmptyWalletsSection
        ? this.$t('card.limits_and_balances.hide_empty_wallets', { number: this.numberOfEmptyWallets })
        : this.$t('card.limits_and_balances.show_empty_wallets', { number: this.numberOfEmptyWallets });
    },
  },
  methods: {
    toggleEmptyWalletsSection() {
      if (!this.numberOfEmptyWallets) {
        return;
      }
      this.showEmptyWalletsSection = !this.showEmptyWalletsSection;
    },
    sortWallets() {
      let openWallet = {};
      let nonEmptyWallets = [];
      let emptyWallets = [];

      _.filter(this.card.wallets, (wallet) => {
        if (wallet.wallet_definition_id === 'open') {
          openWallet = wallet;
        } else if (wallet.available_balance === 0 && this.walletHoldBalance(wallet) === 0) {
          emptyWallets.push(wallet);
        } else {
          nonEmptyWallets.push(wallet);
        }
      });

      this.sortedWalletsOpenWalletFirst = this.sortNonEmptyWallets(nonEmptyWallets, openWallet);
      this.emptyWalletsAlphabetical = this.sortEmptyWallets(emptyWallets);
    },
    sortNonEmptyWallets(nonEmptyWallets, openWallet) {
      const sortedNonEmpty = nonEmptyWallets.sort(function(a, b) {
        return b.available_balance - a.available_balance;
      });
      sortedNonEmpty.unshift(openWallet);

      return sortedNonEmpty;
    },
    sortEmptyWallets(emptyWallets) {
      return _.sortBy(emptyWallets, ['wallet_definition_name']);
    },
    walletHoldBalance(wallet) {
      return _.round(wallet.actual_balance - wallet.available_balance, 2);
    },
    showUpdateCardMaxBalanceFeature() {
      return (
        this.classicProgram &&
        this.isAgentInternal() &&
        (this.card.status === 'active' || this.card.status === 'pending_activation' || this.card.status === 'locked')
      );
    },
  },
};
</script>
