import Vue from 'vue';

const service = {
  async getSpace(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/space/v1/spaces/${id}`);
    return response.data.data;
  },
  async createSpace(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/space/v1/spaces/augmented`, payload);
    return response.data.data;
  },
  async listSpacesWithPartner(keyword, page, limit) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/space/v1/spaces/with-partner', page, limit, params)}`,
    );
    return response.data.data;
  },
  async setSpacePublic(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/space/v1/spaces/${id}/set-public`, {});
    return response.data.data;
  },
  async setSpacePrivate(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/space/v1/spaces/${id}/set-private`, {});
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, { keyword } = {}) {
  let path = startPath;
  if (page || limit || keyword) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
