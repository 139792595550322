<template>
  <card v-if="dataReady" :title="$t('user.details.aml_profile_section.title')" icon="" :header-divider="true" id="aml-profile">
    <template slot="headerZoneRight">
      <label-text
        v-if="_get(application, 'card_applicant.compliance.fatca_crs')"
        :label="$t('user.details.aml_profile_section.fatca.title')"
        :value="
          _get(application, 'card_applicant.compliance.fatca_crs.foreign_tax_obligations')
            ? $t(`code.aml_profile.fatca.${application.card_applicant.compliance.fatca_crs.foreign_tax_obligations}`)
            : $t('code.aml_profile.fatca.false')
        "
        :class-array="[`fatca-${application.card_applicant.compliance.fatca_crs.foreign_tax_obligations}`, 'aml-profile--fatca']"
      ></label-text>
      <label-text v-else :label="$t('user.details.aml_profile_section.fatca.title')"></label-text>

      <label-text
        v-if="_get(application, 'card_applicant.compliance.third_party_determination')"
        :label="$t('user.aml_profile.third_party.title')"
        :value="
          isThirdPartyDetermination
            ? $t(`code.aml_profile.third_party_determination.${application.card_applicant.compliance.third_party_determination.is_third_party}`)
            : $t('code.aml_profile.third_party_determination.false')
        "
        :class-array="[`third-party-${isThirdPartyDetermination}`, 'aml-profile--third-party']"
      ></label-text>
      <label-text v-else :label="$t('user.aml_profile.third_party.title')"></label-text>

      <label-text
        v-if="showAmlStatus"
        :label="$t('user.aml_profile.peps_match')"
        :value="accountAmlStatus.pep_matched ? $t(`code.aml_profile.pep_matched.${accountAmlStatus.pep_matched}`) : $t('system.na')"
        :class-array="[`pep-matched-${accountAmlStatus.pep_matched}`, 'aml-profile--pep-matched-field']"
      ></label-text>
      <label-text v-else :label="$t('user.aml_profile.peps_match')"></label-text>

      <label-text
        v-if="showAmlStatus"
        :label="$t('user.aml_profile.sanctions_match')"
        :value="accountAmlStatus.sanctions_matched ? $t(`code.aml_profile.sanctions_matched.${accountAmlStatus.sanctions_matched}`) : $t('system.na')"
        :class-array="[`sanctions-matched-${accountAmlStatus.sanctions_matched}`, 'aml-profile--sanctions-matched-field']"
      ></label-text>
      <label-text v-else :label="$t('user.aml_profile.sanctions_match')"></label-text>

      <label-text
        v-if="account.risk_rating"
        :label="$t(`user.aml_profile.risk_rating`)"
        :value="$t(`code.card.risk_rating.${account.risk_rating}`)"
        :class-array="['risk-rating', `risk-rating-${account.risk_rating}`, 'aml-profile--risk-rating-field']"
      ></label-text>
      <label-text v-else :label="$t('user.aml_profile.risk_rating')"></label-text>

      <label-text
        :label="$t(`user.aml_profile.aml_flag`)"
        :value="$t(`code.card.aml_flag.${account.aml_flag}`)"
        :class-array="['aml-flag', `aml-flag-${account.aml_flag}`, 'aml-profile--aml-flag-field']"
      ></label-text>
      <div v-if="canManageAmlProgram()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="canManageAmlProgram()" :label="`${$t('user.aml_profile.edit')}`" value="3" class="aml-profile-section--edit">
        <link-secondary-action slot="labelTextLink" @click="showModalEdit"> {{ $t('user.aml_profile.edit_button') }} </link-secondary-action>
      </label-text>
    </template>

    <template slot="content">
      <modal-action v-if="canManageAmlProgram()" :show-modal="showEditModal">
        <edit-account-aml-profile-action
          :account-id="account.id"
          :aml-flag="account.aml_flag"
          :risk-rating="account.risk_rating"
          :peps-matched="accountAmlStatus && accountAmlStatus.pep_matched ? accountAmlStatus.pep_matched : 'na'"
          :sanctions-matched="accountAmlStatus && accountAmlStatus.sanctions_matched ? accountAmlStatus.sanctions_matched : 'na'"
          @close="closeModalEdit"
          @action="reloadPage"
        />
      </modal-action>

      <template v-if="account.cardholder_id">
        <template v-if="showAmlStatus">
          <h4>{{ $t('user.aml_profile.peps_title') }}</h4>
          <v-layout row>
            <label-text
              :label="$t('user.aml_profile.peps_last_verified')"
              :value="accountAmlStatus ? $options.filters.formatDateNoTime(accountAmlStatus.pep_last_assessment_check_date) : $t('system.na')"
            ></label-text>
          </v-layout>
          <v-layout row>
            <user-aml-status-logs-table
              v-if="pepsHistory && pepsHistory.length > 0"
              :items="pepsHistory"
              :card-applicant="application.card_applicant"
            ></user-aml-status-logs-table>
          </v-layout>

          <h4>{{ $t('user.aml_profile.sanctions_title') }}</h4>
          <v-layout row>
            <label-text
              :label="$t('user.aml_profile.sanctions_last_verified')"
              :value="accountAmlStatus ? $options.filters.formatDateNoTime(accountAmlStatus.sanctions_last_assessment_check_date) : $t('system.na')"
            ></label-text>
          </v-layout>
          <v-layout row>
            <user-aml-status-logs-table
              v-if="sanctionsHistory && sanctionsHistory.length > 0"
              :items="sanctionsHistory"
              :card-applicant="application.card_applicant"
            ></user-aml-status-logs-table>
          </v-layout>
        </template>
        <template v-else>
          <v-alert v-if="account.cardholder_id" type="info" :value="true"> {{ $t('aml.aml_status.not_generated') }} </v-alert>
        </template>
      </template>

      <template v-if="application">
        <h4>{{ $t('user.aml_profile.fatca.title') }}</h4>
        <fatca-table v-if="isFatca" :fatca="application.card_applicant.compliance.fatca_crs" />
        <v-layout v-if="!isFatca">
          <p>{{ $t('system.na') }}</p>
        </v-layout>

        <h4>{{ $t('user.aml_profile.third_party.title') }}</h4>
        <div v-if="isThirdPartyDetermination" class="data-card--content-zone">
          <v-flex xs12 class="d-inline-flex data-card--content-zone__middle">
            <label-text
              :label="$t('user.aml_profile.third_party.type')"
              :value="application.card_applicant.compliance.third_party_determination.third_party.type"
            ></label-text>
            <label-text
              v-if="application.card_applicant.compliance.third_party_determination.third_party.type === 'corporate'"
              :label="$t('user.aml_profile.third_party.incorporation_number')"
              :value="application.card_applicant.compliance.third_party_determination.third_party.incorporation_number"
            ></label-text>
            <label-text
              v-if="application.card_applicant.compliance.third_party_determination.third_party.type === 'corporate'"
              :label="$t('user.aml_profile.third_party.jurisdiction')"
              :value="application.card_applicant.compliance.third_party_determination.third_party.jurisdiction"
            ></label-text>
            <label-text
              :label="$t('user.aml_profile.third_party.first_name')"
              :value="_get(application.card_applicant, 'compliance.third_party_determination.third_party.first_name')"
            ></label-text>
            <label-text
              :label="$t('user.aml_profile.third_party.last_name')"
              :value="_get(application.card_applicant, 'compliance.third_party_determination.third_party.last_name')"
            ></label-text>
            <label-text
              :label="$t('user.aml_profile.third_party.relationship')"
              :value="_get(application.card_applicant, 'compliance.third_party_determination.third_party.relationship')"
            ></label-text>
            <label-text
              :label="$t('user.aml_profile.third_party.occupation')"
              :value="_get(application.card_applicant, 'compliance.third_party_determination.third_party.occupation')"
            ></label-text>
            <label-text
              :label="$t('user.aml_profile.third_party.date_of_birth')"
              :value="_get(application.card_applicant, 'compliance.third_party_determination.third_party.date_of_birth')"
            ></label-text>
            <label-text :label="$t('user.aml_profile.third_party.address')" :value="fullAddress"></label-text>
          </v-flex>
        </div>
        <v-layout v-else>
          <p>{{ $t('system.na') }}</p>
        </v-layout>
      </template>
      <v-layout v-else>
        <label-none>{{ $t('system.na') }}</label-none>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import UserAmlStatusLogsTable from './UserAmlStatusLogsTable.vue';
import EditAccountAmlProfileAction from '../actions/EditAccountAmlProfileAction.vue';
import LabelNone from '@/components/system/Labels/LabelNone.vue';

export default {
  name: 'user-aml-profile',
  components: { LabelNone, EditAccountAmlProfileAction, UserAmlStatusLogsTable },
  mixins: [actionErrorTrackable, security],
  data() {
    return {
      showEditModal: false,
      dataReady: false,
    };
  },
  watch: {
    async account() {
      await this.fetch();
    },
  },
  computed: {
    ...mapGetters('account', ['account', 'accountAmlStatus', 'accountAmlStatusLogsAndAssessmentChecks']),
    ...mapGetters('application', ['application']),
    showAmlStatus() {
      return !_.isEmpty(this.accountAmlStatus);
    },
    isFatca() {
      return _get(this.application, 'card_applicant.compliance.fatca_crs.foreign_tax_obligations', false);
    },
    isThirdPartyDetermination() {
      return _get(this.application, 'card_applicant.compliance.third_party_determination.is_third_party', false);
    },
    fullAddress() {
      return _get(this.application, 'card_applicant')
        ? `${this.application.card_applicant.compliance.third_party_determination.third_party.address.state}, ${this.application.card_applicant.compliance.third_party_determination.third_party.address.city}, ${this.application.card_applicant.compliance.third_party_determination.third_party.address.state}, ${this.application.card_applicant.compliance.third_party_determination.third_party.address.country}, ${this.application.card_applicant.compliance.third_party_determination.third_party.address.zip}`
        : null;
    },
    pepsHistory() {
      if (this.accountAmlStatusLogsAndAssessmentChecks.pepLogs) {
        return _.map(this.accountAmlStatusLogsAndAssessmentChecks.pepLogs, (log) => {
          const logForDisplay = {};
          logForDisplay.created = log.created;
          logForDisplay.user = log.user;
          logForDisplay.change = log.change;
          logForDisplay.list = log.list || null;
          logForDisplay.view_details = log.view_details || null;
          logForDisplay.type = 'peps';

          return logForDisplay;
        });
      }
      return undefined;
    },
    sanctionsHistory() {
      if (this.accountAmlStatusLogsAndAssessmentChecks.sanctionLogs) {
        return _.map(this.accountAmlStatusLogsAndAssessmentChecks.sanctionLogs, (log) => {
          const logForDisplay = {};
          logForDisplay.created = log.created;
          logForDisplay.user = log.user;
          logForDisplay.change = log.change;
          logForDisplay.list = log.list || null;
          logForDisplay.view_details = log.view_details || null;
          logForDisplay.type = 'sanctions';

          return logForDisplay;
        });
      }
      return undefined;
    },
  },
  methods: {
    _get,
    displayCard() {
      this.dataReady = true;
    },
    showModalEdit() {
      this.showEditModal = true;
    },
    closeModalEdit() {
      this.showEditModal = false;
    },
    reloadPage() {
      this.showEditModal = false;
    },
    async fetch() {
      if (this.account) {
        await this.executeAction({ action: 'application/getApplication' }, this.account.card_application_id);

        await this.executeAction({ action: 'account/getAccountAmlStatus' }, this.account.id);

        if (this.accountAmlStatus) {
          await this.executeAction({ action: 'account/getAccountAmlStatusLogsAndAssessmentChecks' }, this.account.id);
        }

        this.displayCard();
      }
    },
  },
};
</script>
