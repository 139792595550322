<template>
  <card :title="$t('offers.details.offers_information_section.title')" icon="fal fa-money-check-edit" id="offers-information">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('offers.details.offers_information_section.id')}`" :value="offer.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ offer.id }}
            <button class="clipboard" v-clipboard:copy="offer.id ? offer.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('offers.details.offers_information_section.type')" :value="offerTypeWithMeta" />
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('offers.details.offers_information_section.timezone')}`" :value="offer.timezone" />
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="offer.external_id" :label="`${$t('offers.details.offers_information_section.external_id')}`" :value="offer.external_id" />
      <div v-if="offer.external_id" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('offers.details.offers_information_section.system_status.status')}`" :value="offer.system_status.status">
        <offer-status-view slot="labelTextLink" :status="offer.system_status.status" />
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('offers.details.offers_information_section.created')}`"
        :value="offer.creation_date | formatShortDateInverted | orNotAvailable"
      />

      <div v-if="showActionBtnVoucher()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action v-if="showActionBtnVoucher()" slot="labelTextLink">
        <button-secondary-actions class="application-info--actions" :text="$t(`system.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>
    </template>

    <template slot="content">
      <modal-action :show-modal="showExtendOfferVoucherModal"
        ><extend-offer-voucher-expiration-action
          v-if="showExtendOfferVoucherModal"
          :offer-obj="offer"
          :hello-show="showExtendOfferVoucherModal"
          @close="closeExtendOfferVoucherModal"
          @updated="extendOfferVoucherSuccess"
        ></extend-offer-voucher-expiration-action
      ></modal-action>
      <modal-action :show-modal="showUpdateBonusOffersModal"
        ><update-bonus-offers-action
          v-if="showUpdateBonusOffersModal"
          :offer="offer"
          :hello-show="showUpdateBonusOffersModal"
          @close="closeUpdateBonusOffersModal"
          @updated="updateBonusOffersSuccess"
        ></update-bonus-offers-action
      ></modal-action>
      <modal-action :show-modal="showUpdateGiftCertificateOffersModal"
        ><update-gift-certificate-offers-action
          v-if="showUpdateGiftCertificateOffersModal"
          :offer="offer"
          :hello-show="showUpdateGiftCertificateOffersModal"
          @close="closeUpdateGiftCertificateOffersModal"
          @updated="updateGiftCertificateOffersSuccess"
        ></update-gift-certificate-offers-action
      ></modal-action>
      <modal-action :show-modal="showUpdateConsumerDetailsModal">
        <update-offer-consumer-details-action
          v-if="showUpdateConsumerDetailsModal"
          :offer="offer"
          :hello-show="showUpdateConsumerDetailsModal"
          @close="closeUpdateConsumerDetailsModal"
          @updated="updateConsumerDetailsSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showUpdateCorporateDetailsModal">
        <update-offer-corporate-details-action
          v-if="showUpdateCorporateDetailsModal"
          :offer="offer"
          :hello-show="showUpdateCorporateDetailsModal"
          @close="closeUpdateCorporateDetailsModal"
          @updated="updateCorporateDetailsSuccess"
        />
      </modal-action>
      <v-layout row>
        <v-flex xs3>
          <label-text :label="$t(`offers.details.offers_information_section.program`)" :value="offer.partner_id" />
        </v-flex>
        <v-flex xs3>
          <label-text :label="$t(`offers.details.offers_information_section.owner_business`)" :value="offer.owner_business_id">
            <p v-if="offer.owner_business_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'business_details', params: { businessId: offer.owner_business_id } }">
                {{ offer.owner_business_name }} ({{ offer.owner_business_id }})
              </router-link>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3 v-if="offer.branding">
          <label-text :label="$t(`offers.details.offers_information_section.branding`)" :value="offer.branding.name">
            <p v-if="offer.branding_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'branding_details', params: { brandingId: offer.branding_id } }">
                {{ offer.branding.name }} ({{ offer.branding_id }})
              </router-link>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="`${$t('offers.details.offers_information_section.is_single_publisher')}`"
            :value="$t(`offers.single_publisher.${offer.is_single_publisher}`)"
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <h4>{{ $t('offers.details.offers_information_section.statuses_title') }}</h4>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="`${$t('offers.details.offers_information_section.system_status.pad_funding')}`"
            :value="offer.system_status.pad_funding"
          >
            <ready-status-view class="label-text--value" slot="labelTextLink" :status="offer.system_status.pad_funding" />
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text :label="`${$t('offers.details.offers_information_section.system_status.pad_fee')}`" :value="offer.system_status.pad_fee">
            <ready-status-view class="label-text--value" slot="labelTextLink" :status="offer.system_status.pad_fee" />
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="`${$t('offers.details.offers_information_section.system_status.transactional')}`"
            :value="offer.system_status.transactional"
          >
            <ready-status-view class="label-text--value" slot="labelTextLink" :status="offer.system_status.transactional" />
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="`${$t('offers.details.offers_information_section.system_status.purchasable')}`"
            :value="offer.system_status.purchasable"
          >
            <ready-status-view class="label-text--value" slot="labelTextLink" :status="offer.system_status.purchasable" />
          </label-text>
        </v-flex>
      </v-layout>

      <v-layout row v-if="!this.isBonusOffer">
        <h4>{{ $t('offers.details.offers_information_section.consumer_gift_program.title') }}</h4>
      </v-layout>
      <v-layout row v-if="!this.isBonusOffer">
        <v-flex xs3 v-if="offer.consumer">
          <label-text :label="`${$t('offers.details.offers_information_section.consumer_gift_program.status')}`" :value="offer.consumer.enabled">
            <enabled-disabled-status-view slot="labelTextLink" :enabled="offer.consumer.enabled" />
          </label-text>
        </v-flex>
        <v-flex xs3 v-if="offer.consumer && offer.consumer.enabled">
          <label-text
            :label="$t(`offers.details.offers_information_section.consumer_gift_program.purchase_limits`)"
            :value="consumerGiftProgramPurchaseLimits"
          />
        </v-flex>
        <v-flex xs3 v-if="offer.consumer && offer.consumer.purchase_default_amount">
          <label-text
            :label="$t(`offers.details.offers_information_section.consumer_gift_program.purchase_default_amount`)"
            :value="offer.consumer.purchase_default_amount | currency | dollarSignI18n"
          />
        </v-flex>
        <v-flex xs3 v-if="offer.consumer && offer.consumer.enabled">
          <label-text :label="$t(`offers.details.offers_information_section.consumer_gift_program.purchase_inventory`)">
            <p v-if="offer.consumer.purchase_inventory_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'inventory_details', params: { inventoryId: offer.consumer.purchase_inventory_id } }">
                {{ offer.consumer.purchase_inventory.name }} ({{ offer.consumer.purchase_inventory_id }})
              </router-link>
              - {{ offer.consumer.purchase_inventory.available_amount | currency | dollarSignI18n }}
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
      </v-layout>

      <v-layout row v-if="!this.isBonusOffer">
        <h4>{{ $t('offers.details.offers_information_section.corporate_gift_program.title') }}</h4>
      </v-layout>
      <v-layout row v-if="!this.isBonusOffer">
        <v-flex xs3 v-if="offer.corporate">
          <label-text :label="`${$t('offers.details.offers_information_section.corporate_gift_program.status')}`" :value="offer.corporate.enabled">
            <enabled-disabled-status-view slot="labelTextLink" :enabled="offer.corporate.enabled" />
          </label-text>
        </v-flex>
        <v-flex xs3 v-if="offer.corporate && offer.corporate.enabled">
          <label-text
            :label="$t(`offers.details.offers_information_section.corporate_gift_program.voucher_structure`)"
            :value="this.$t(`offers.voucher_structures.${offer.corporate.voucher_structure}`)"
          />
        </v-flex>
        <v-flex xs3 v-if="offer.corporate && offer.corporate.enabled">
          <label-text
            :label="$t(`offers.details.offers_information_section.corporate_gift_program.minimum_purchase_amount`)"
            :value="offer.corporate.minimum_purchase_amount | currency | dollarSignI18n"
          />
        </v-flex>
      </v-layout>
      <v-layout row v-if="!this.isBonusOffer">
        <v-flex xs3 v-if="offer.corporate && offer.corporate.enabled">
          <label-text
            :label="$t(`offers.details.offers_information_section.corporate_gift_program.purchase_fee`)"
            :value="corporateGiftProgramPurchaseFee"
          />
        </v-flex>
        <v-flex xs3 v-if="offer.corporate && offer.corporate.enabled">
          <label-text
            :label="$t(`offers.details.offers_information_section.corporate_gift_program.minimum_fee`)"
            :value="offer.corporate.minimum_fee | currency | dollarSignI18n"
          />
        </v-flex>
        <v-flex xs3 v-if="offer.corporate && offer.corporate.enabled">
          <label-text :label="$t(`offers.details.offers_information_section.corporate_gift_program.fee_inventory`)">
            <p v-if="offer.corporate.fee_inventory_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'inventory_details', params: { inventoryId: offer.corporate.fee_inventory_id } }">
                {{ offer.corporate.fee_inventory.name }} ({{ offer.corporate.fee_inventory_id }})
              </router-link>
              - {{ offer.corporate.fee_inventory.available_amount | currency | dollarSignI18n }}
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <h4>{{ $t('offers.details.offers_information_section.incentive_title') }}</h4>
      </v-layout>
      <v-layout row>
        <v-flex xs3 v-if="isGiftCertificateOffer">
          <label-text
            :label="$t(`offers.details.offers_information_section.incentive_default_payment_amount`)"
            :value="offer.incentive.payment_amount | dollarSignI18n"
          ></label-text
        ></v-flex>
        <v-flex xs3 v-if="isBonusOffer">
          <label-text
            v-if="offer.incentive"
            :label="$t(`offers.details.offers_information_section.incentive_bonus`)"
            :value="offer.incentive.bonus_percent + ' %'"
          />
        </v-flex>
        <v-flex xs3 v-else>
          <label-text
            v-if="offer.incentive"
            :label="$t(`offers.details.offers_information_section.incentive_default_bonus`)"
            :value="offer.incentive.bonus_amount | dollarSignI18n"
          />
        </v-flex>
        <v-flex xs3
          ><label-text
            v-if="offer.incentive"
            :label="$t(`offers.details.offers_information_section.duration_model`)"
            :value="$t(`offers.incentive_duration_models.${offer.incentive.duration_model}`)"
          ></label-text
        ></v-flex>
        <v-flex xs3>
          <label-text
            v-if="offer.incentive && offer.incentive.duration_model === 'relative'"
            :label="$t(`offers.details.offers_information_section.duration`)"
            :value="$tc('offers.days', offer.incentive.bonus_duration_days, { count: offer.incentive.bonus_duration_days })"
          ></label-text>
          <label-text
            v-if="offer.incentive && offer.incentive.duration_model === 'fixed'"
            :label="$t(`offers.details.offers_information_section.bonus_end_date`)"
            :value="
              (offer.incentive.bonus_end_date + ' ' + offer.incentive.bonus_end_time)
                | formatShortDateTimeNoSecondsInverted({ withAgo: true, timezone: offer.timezone })
            "
          ></label-text
        ></v-flex>
      </v-layout>
      <v-layout row v-if="isBonusOffer">
        <v-flex xs3
          ><label-text
            v-if="offer.incentive"
            :label="$t(`offers.details.offers_information_section.minimum`)"
            :value="offer.incentive.minimum_payment | dollarSignI18n"
          ></label-text
        ></v-flex>
        <v-flex xs3
          ><label-text
            v-if="offer.incentive"
            :label="$t(`offers.details.offers_information_section.step`)"
            :value="offer.incentive.step | dollarSignI18n"
          ></label-text
        ></v-flex>
        <v-flex xs3
          ><label-text
            v-if="offer.incentive"
            :label="$t(`offers.details.offers_information_section.default`)"
            :value="offer.incentive.default_payment | dollarSignI18n"
          ></label-text
        ></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3
          ><label-text
            v-if="offer.incentive && !isGiftCertificateOffer"
            :label="$t(`offers.details.offers_information_section.limit`)"
            :value="offer.incentive.user_inventory_limit | dollarSignI18n"
          ></label-text
        ></v-flex>
        <v-flex xs3 v-if="userPaymentLimit"
          ><label-text
            :label="$t(`offers.details.offers_information_section.user_payment_limit`)"
            :value="userPaymentLimit | currency | dollarSignI18n"
          ></label-text
        ></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3
          ><label-text
            v-if="offer.incentive"
            :label="$t(`offers.details.offers_information_section.refund_method`)"
            :value="$t(`offers.incentive_refund_methods.${offer.incentive.refund_method}`)"
          ></label-text
        ></v-flex>
        <v-flex xs3 v-if="offer.incentive.refund_method !== 'no_refund'"
          ><label-text
            v-if="offer.incentive && offer.incentive.duration_model === 'relative'"
            :label="$t(`offers.details.offers_information_section.closure`)"
            :value="$tc('offers.days', offer.incentive.closure_days, { count: offer.incentive.closure_days })"
          ></label-text>
          <label-text
            v-if="offer.incentive && offer.incentive.duration_model === 'fixed'"
            :label="$t(`offers.details.offers_information_section.closure_end_date`)"
            :value="
              (offer.incentive.closure_end_date + ' ' + offer.incentive.closure_end_time)
                | formatShortDateTimeNoSecondsInverted({ withAgo: true, timezone: offer.timezone })
            "
          ></label-text
        ></v-flex>
      </v-layout>
      <v-layout row>
        <h4>{{ $t('offers.details.offers_information_section.availability_title') }}</h4>
      </v-layout>
      <v-layout row>
        <v-flex xs3 v-if="offer.availability && offer.availability.inventory_id"
          ><label-text :label="$t(`offers.details.offers_information_section.inventory_title`)">
            <p class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'inventory_details', params: { inventoryId: offer.availability.inventory_id } }">
                {{ offer.availability.inventory.name }} ({{ offer.availability.inventory_id }})
              </router-link>
              - {{ offer.availability.inventory.available_amount | currency | dollarSignI18n }}
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3 v-if="offer.availability && offer.availability.inventory_id"
          ><label-text
            :label="$t(`offers.details.offers_information_section.is_refillable`)"
            :value="$t(`offers.details.offers_information_section.inventory_refillable.${offer.availability.is_refillable}`)"
          ></label-text
        ></v-flex>
        <v-flex xs3 v-if="offer.availability && !offer.is_single_publisher">
          <label-text
            :label="$t(`offers.details.offers_information_section.default_publisher_limit`)"
            :value="offer.availability.publisher_default_inventory_limit | currency({ fractionCount: 0 }) | dollarSignI18n"
          ></label-text
        ></v-flex>
        <v-flex xs3 v-if="publisherPaymentLimit && !offer.is_single_publisher">
          <label-text
            :label="$t(`offers.details.offers_information_section.default_publisher_payment_limit`)"
            :value="publisherPaymentLimit | currency | dollarSignI18n"
          ></label-text
        ></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text v-if="offer.availability" :label="$t(`offers.details.offers_information_section.start_date`)">
            <p class="label-text--value" slot="labelTextLink">
              {{
                (offer.availability.start_date + ' ' + offer.availability.start_time)
                  | formatShortDateTimeNoSecondsInverted({ withAgo: true, timezone: offer.timezone })
              }}
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            v-if="offer.availability"
            :label="$t(`offers.details.offers_information_section.end_date`)"
            :value="offerAvailabilityEndDate | formatShortDateTimeNoSecondsInverted({ withAgo: true, timezone: offer.timezone, returnNull: true })"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <h4>{{ $t('offers.details.offers_information_section.accounting.title') }}</h4>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs3>
          <label-text
            :label="$t(`offers.details.offers_information_section.accounting.customer_type.label`)"
            :value="$t(`offers.customer_type.${offer.customer_type}`)"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text :label="$t(`offers.details.offers_information_section.accounting.reference_id`)" :value="offer.reference_id"> </label-text>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs3 v-if="offer.bonus_breakage_owner">
          <label-text :label="$t(`offers.details.offers_information_section.breakage_bonus_owner`)" :value="offer.bonus_breakage_owner.name">
            <p v-if="offer.bonus_breakage_owner_business_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'business_details', params: { businessId: offer.bonus_breakage_owner_business_id } }">
                {{ offer.bonus_breakage_owner.name }} ({{ offer.bonus_breakage_owner_business_id }})
              </router-link>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
        <v-flex xs3 v-if="offer.payment_breakage_owner">
          <label-text :label="$t(`offers.details.offers_information_section.breakage_payment_owner`)" :value="offer.payment_breakage_owner.name">
            <p v-if="offer.payment_breakage_owner_business_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'business_details', params: { businessId: offer.payment_breakage_owner_business_id } }">
                {{ offer.payment_breakage_owner.name }} ({{ offer.payment_breakage_owner_business_id }})
              </router-link>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
      </v-layout>

      <v-layout row>
        <h4>{{ $t('offers.details.offers_information_section.features_title') }}</h4>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs3>
          <label-text
            :label="$t(`offers.details.offers_information_section.send_reminder_emails`)"
            :value="$t(`boolean.${offer.features.send_reminder_emails}`)"
          >
          </label-text>
        </v-flex>
        <v-flex v-if="isBonusOffer" xs3>
          <label-text
            :label="$t(`offers.details.offers_information_section.crowdfunding_title`)"
            :value="$t(`boolean.${offer.features.crowdfunding}`)"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`offers.details.offers_information_section.private_title`)"
            :value="$t(`boolean.${offer.features.private}`)"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`offers.details.offers_information_section.cash_flow_optimization_title`)"
            :value="$t(`boolean.${offer.features.cash_flow_optimization}`)"
          ></label-text>
        </v-flex>
        <v-flex v-if="isBonusOffer" xs3>
          <label-text
            :label="$t(`offers.details.offers_information_section.restricted_to_members`)"
            :value="$t(`boolean.${offer.features.restricted_to_members}`)"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`offers.details.offers_information_section.hide_store_before_start_date`)"
            :value="$t(`boolean.${offer.features.hide_store_before_start_date}`)"
          ></label-text>
        </v-flex>
        <v-flex xs3 v-if="isGiftCertificateOffer">
          <label-text
            :label="$t(`offers.details.offers_information_section.hide_activation_button`)"
            :value="$t(`boolean.${offer.features.hide_activation_button}`)"
          ></label-text>
        </v-flex>
        <v-flex xs3 v-if="isGiftCertificateOffer">
          <label-text
            :label="$t(`offers.details.offers_information_section.hide_purchase_button`)"
            :value="$t(`boolean.${offer.features.hide_purchase_button}`)"
          ></label-text>
        </v-flex>
        <v-flex>
          <label-text :label="$t(`offers.details.offers_information_section.slug_title`)">
            <p class="label-text--value" slot="labelTextLink">
              {{ offer.features.slug | orNone }}
            </p>
          </label-text>
        </v-flex>
      </v-layout>

      <v-layout row>
        <h4>{{ $t('offers.details.offers_information_section.funding_title') }}</h4>
      </v-layout>
      <v-layout row>
        <v-flex xs3 v-if="offer.contributor">
          <label-text :label="$t(`offers.details.offers_information_section.contributor`)" :value="offer.contributor.name">
            <p v-if="offer.contributor_business_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'business_details', params: { businessId: offer.contributor_business_id } }">
                {{ offer.contributor.name }} ({{ offer.contributor_business_id }})
              </router-link>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <h5>{{ $t('offers.details.offers_information_section.bonus_title') }}</h5>
      </v-layout>
      <v-layout row>
        <v-flex xs3
          ><label-text
            v-if="offer.funding"
            :label="$t(`offers.details.offers_information_section.funding_trigger`)"
            :value="$t(`offers.funding_triggers.${offer.funding.bonus.trigger}`)"
          ></label-text
        ></v-flex>
        <v-flex xs3
          ><label-text
            v-if="offer.funding && offer.funding.bonus"
            :label="$t(`offers.details.offers_information_section.funding_bonus_contributor`)"
            :value="offer.funding.bonus.contributor + ' %'"
          ></label-text
        ></v-flex>
        <v-flex xs3
          ><label-text
            v-if="offer.funding"
            :label="$t(`offers.details.offers_information_section.funding_bonus_publisher`)"
            :value="offer.funding.bonus.publisher + ' %'"
          ></label-text
        ></v-flex>
        <v-flex xs3
          ><label-text
            v-if="offer.funding && offer.funding.bonus"
            :label="$t(`offers.details.offers_information_section.funding_bonus_store`)"
            :value="offer.funding.bonus.store + ' %'"
          ></label-text
        ></v-flex>
      </v-layout>
      <v-layout row>
        <h5>{{ $t('offers.details.offers_information_section.payment_title') }}</h5>
      </v-layout>
      <v-layout row>
        <v-flex xs3
          ><label-text
            v-if="offer.funding"
            :label="$t(`offers.details.offers_information_section.funding_trigger`)"
            :value="$t(`offers.funding_payer_triggers.${offer.funding.payer}`)"
          ></label-text
        ></v-flex>
      </v-layout>

      <v-layout row>
        <h4>{{ $t('offers.details.offers_information_section.fees_title') }}</h4>
      </v-layout>
      <v-layout row>
        <label-text :label="$t(`offers.details.offers_information_section.fee_payment_method_label`)" :value="offer.fee_payment_method">
          <template slot="labelTextLink">
            <p v-if="offer.fee_payment_method !== 'none'" class="label-text--value">
              {{ $t(`offers.fee_payment_methods.${offer.fee_payment_method}`) }}
            </p>
            <label-none v-else>{{ $t(`offers.fee_payment_methods.${offer.fee_payment_method}`) }}</label-none>
          </template>
        </label-text>
      </v-layout>
      <template v-if="hasAutomatedFeesPaymentMethod">
        <v-layout row>
          <h5 v-if="isBonusOffer" style="text-transform: uppercase;">{{ $t('offers.details.offers_information_section.purchase_fee_title') }}</h5>
          <h5 v-else style="text-transform: uppercase;">{{ $t('offers.details.offers_information_section.activation_fee_title') }}</h5>
        </v-layout>
        <v-layout row>
          <h5>{{ $t('offers.details.offers_information_section.purchase_fee_percent_fee_title') }}</h5>
        </v-layout>
        <v-layout row>
          <v-flex xs3 v-if="offer.purchase_fee && offer.purchase_fee.percent_fee"
            ><label-text
              v-if="offer.purchase_fee.percent_fee.payer !== 'none'"
              :label="$t(`offers.details.offers_information_section.purchase_percent_fee_payer`)"
              :value="$t(`offers.fee_purchase_payer.${offer.purchase_fee.percent_fee.payer}`)"
            >
            </label-text>
            <template v-else>
              <h5 class="sub-title">{{ $t(`offers.details.offers_information_section.purchase_percent_fee_payer`) }}</h5>
              <p class="info-not-available">{{ $t(`offers.fee_purchase_payer.${offer.purchase_fee.percent_fee.payer}`) | orNone }}</p>
            </template>
          </v-flex>
          <v-flex xs3 v-if="offer.purchase_fee && offer.purchase_fee.percent_fee && offer.purchase_fee.percent_fee.payer !== 'none'"
            ><label-text
              :label="$t(`offers.details.offers_information_section.purchase_percent_fee_ratio`)"
              :value="offer.purchase_fee.percent_fee.ratio + ' %'"
            ></label-text
          ></v-flex>
          <v-flex xs3 v-if="offer.purchase_fee && offer.purchase_fee.percent_fee && offer.purchase_fee.percent_fee.payer !== 'none'"
            ><label-text
              :label="$t(`offers.details.offers_information_section.purchase_percent_fee_scope`)"
              :value="$t(`offers.fee_purchase_scope.${offer.purchase_fee.percent_fee.scope}`)"
            ></label-text
          ></v-flex>
        </v-layout>
        <v-layout row>
          <h5>{{ $t('offers.details.offers_information_section.purchase_fee_fixed_fee_title') }}</h5>
        </v-layout>
        <v-layout row>
          <v-flex xs3 v-if="offer.purchase_fee && offer.purchase_fee.fixed_fee"
            ><label-text
              v-if="offer.purchase_fee.fixed_fee.payer !== 'none'"
              :label="$t(`offers.details.offers_information_section.purchase_fixed_fee_payer`)"
              :value="$t(`offers.fee_purchase_payer.${offer.purchase_fee.fixed_fee.payer}`)"
            ></label-text>
            <template v-else>
              <h5 class="sub-title">{{ $t(`offers.details.offers_information_section.purchase_percent_fee_payer`) }}</h5>
              <p class="info-not-available">{{ $t(`offers.fee_purchase_payer.${offer.purchase_fee.fixed_fee.payer}`) | orNone }}</p>
            </template>
          </v-flex>
          <v-flex xs3
            ><label-text
              v-if="offer.purchase_fee && offer.purchase_fee.fixed_fee && offer.purchase_fee.fixed_fee.payer !== 'none'"
              :label="$t(`offers.details.offers_information_section.purchase_fixed_fee_amount`)"
              :value="offer.purchase_fee.fixed_fee.amount | currency | dollarSignI18n"
            ></label-text
          ></v-flex>
        </v-layout>
        <v-layout row>
          <h5 style="text-transform: uppercase;">{{ $t('offers.details.offers_information_section.transaction_fee_title') }}</h5>
        </v-layout>
        <v-layout row>
          <h5>{{ $t('offers.details.offers_information_section.transaction_fee_percent_fee_title') }}</h5>
        </v-layout>
        <v-layout row>
          <v-flex xs3 v-if="offer.transaction_fee && offer.transaction_fee.percent_fee"
            ><label-text
              v-if="offer.transaction_fee.percent_fee.payer !== 'none'"
              :label="$t(`offers.details.offers_information_section.transaction_percent_fee_payer`)"
              :value="$t(`offers.fee_transaction_payer.${offer.transaction_fee.percent_fee.payer}`)"
            ></label-text>
            <template v-else>
              <h5 class="sub-title">{{ $t(`offers.details.offers_information_section.transaction_percent_fee_payer`) }}</h5>
              <p class="info-not-available">{{ $t(`offers.fee_transaction_payer.${offer.transaction_fee.percent_fee.payer}`) | orNone }}</p>
            </template>
          </v-flex>
          <v-flex xs3
            ><label-text
              v-if="offer.transaction_fee && offer.transaction_fee.percent_fee && offer.transaction_fee.percent_fee.payer !== 'none'"
              :label="$t(`offers.details.offers_information_section.transaction_percent_fee_ratio`)"
              :value="offer.transaction_fee.percent_fee.ratio + ' %'"
            ></label-text
          ></v-flex>
          <v-flex xs3
            ><label-text
              v-if="offer.transaction_fee && offer.transaction_fee.percent_fee && offer.transaction_fee.percent_fee.payer !== 'none'"
              :label="$t(`offers.details.offers_information_section.transaction_percent_fee_scope`)"
              :value="$t(`offers.fee_transaction_scope.${offer.transaction_fee.percent_fee.scope}`)"
            ></label-text
          ></v-flex>
        </v-layout>
        <v-layout row>
          <h5>{{ $t('offers.details.offers_information_section.transaction_fee_fixed_fee_title') }}</h5>
        </v-layout>
        <v-layout row>
          <v-flex xs3 v-if="offer.transaction_fee && offer.transaction_fee.fixed_fee"
            ><label-text
              v-if="offer.transaction_fee.fixed_fee.payer !== 'none'"
              :label="$t(`offers.details.offers_information_section.transaction_fixed_fee_payer`)"
              :value="$t(`offers.fee_transaction_payer.${offer.transaction_fee.fixed_fee.payer}`)"
            ></label-text>
            <template v-else>
              <h5 class="sub-title">{{ $t(`offers.details.offers_information_section.transaction_fixed_fee_payer`) }}</h5>
              <p class="info-not-available">{{ $t(`offers.fee_transaction_payer.${offer.transaction_fee.fixed_fee.payer}`) | orNone }}</p>
            </template>
          </v-flex>
          <v-flex xs3
            ><label-text
              v-if="offer.transaction_fee && offer.transaction_fee.fixed_fee && offer.transaction_fee.fixed_fee.payer !== 'none'"
              :label="$t(`offers.details.offers_information_section.transaction_fixed_fee_amount`)"
              :value="offer.transaction_fee.fixed_fee.amount | currency | dollarSignI18n"
            ></label-text
          ></v-flex>
        </v-layout>
      </template>
      <v-layout row>
        <h4>{{ $t('offers.details.offers_information_section.user_fee.title') }}</h4>
      </v-layout>
      <v-layout>
        <v-flex xs3 v-if="offer.user_fee">
          <label-text
            v-if="offer.user_fee.strategy !== 'none'"
            :label="$t(`offers.details.offers_information_section.user_fee.fees_policy`)"
            :value="$t(`offers.details.offers_information_section.user_fee.strategy.${offer.user_fee.strategy}`)"
          ></label-text>
          <template v-else>
            <h5 class="sub-title">{{ $t(`offers.details.offers_information_section.user_fee.fees_policy`) }}</h5>
            <p class="info-not-available">
              {{ $t(`offers.details.offers_information_section.user_fee.strategy.${offer.user_fee.strategy}`) | orNone }}
            </p>
          </template>
        </v-flex>
        <v-flex xs3 v-if="offer.user_fee && offer.user_fee.dormancy && offer.user_fee.strategy !== 'none'">
          <label-text
            :label="$t(`offers.details.offers_information_section.user_fee.dormancy_fee`)"
            :value="offer.user_fee.dormancy.fixed_fee | currency | dollarSignI18n"
          />
        </v-flex>
        <v-flex xs3 v-if="offer.user_fee && offer.user_fee.dormancy && offer.user_fee.strategy !== 'none'">
          <label-text
            :label="$t(`offers.details.offers_information_section.user_fee.dormancy_trigger`)"
            :value="offer.user_fee.dormancy.last_activity_trigger_in_months"
          />
        </v-flex>
        <v-flex xs3 v-if="offer.user_fee && offer.user_fee.dormancy && offer.user_fee.strategy !== 'none'">
          <label-text
            :label="$t(`offers.details.offers_information_section.user_fee.extension_in_months`)"
            :value="offer.user_fee.dormancy.extension_in_months"
          />
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UpdateBonusOffersAction from '../actions/UpdateBonusOffersAction';
import ExtendOfferVoucherExpirationAction from '../actions/ExtendOfferVoucherExpirationAction';
import UpdateGiftCertificateOffersAction from '../actions/UpdateGiftCertificateOffersAction';
import UpdateOfferConsumerDetailsAction from '../actions/UpdateOfferConsumerDetailsAction';
import UpdateOfferCorporateDetailsAction from '../actions/UpdateOfferCorporateDetailsAction';
import { mapGetters } from 'vuex';
import OfferStatusView from '../OfferStatusView';
import LabelNone from '@/components/system/Labels/LabelNone';
import _ from 'lodash';
import { adjustValueForMinStep } from '@/utils/offer-utils';

export default {
  name: 'offers-information',
  components: {
    LabelNone,
    OfferStatusView,
    UpdateBonusOffersAction,
    UpdateGiftCertificateOffersAction,
    UpdateOfferConsumerDetailsAction,
    UpdateOfferCorporateDetailsAction,
    ExtendOfferVoucherExpirationAction,
  },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    const $t = this.$t.bind(this);
    return {
      showExtendOfferVoucherModal: false,
      showUpdateBonusOffersModal: false,
      showUpdateGiftCertificateOffersModal: false,
      showUpdateConsumerDetailsModal: false,
      showUpdateCorporateDetailsModal: false,
      actions: [
        {
          slotName: 'EditOfferModal',
          name: $t('offers.details.offers_information_section.update'),
          action: () => {
            if (this.isBonusOffer) {
              this.openUpdateBonusOffersModal();
            } else {
              this.openUpdateGiftCertificateOffersModal();
            }
          },
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => {
            return !this.showUpdateOffersFeature();
          },
        },
        {
          slotName: 'ExtendVoucherExpirationModal',
          name: $t('voucher.details.voucher_information_section.extend_voucher_expiration'),
          action: () => this.openExtendOfferVoucherModal(), //this.openExtendVoucherExpirationModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showUpdateOffersFeature(),
        },
        {
          slotName: 'UpdateConsumerDetailsModal',
          name: $t('offers.details.offers_information_section.consumer_gift_program.update.title'),
          action: () => this.openUpdateConsumerDetailsModal(),
          isAvailable: () => this.canAccessBeta() && this.canManageOffer() && !this.isBonusOffer,
          isDisabled: () => !this.canManageOffer() && this.isBonusOffer,
        },
        {
          slotName: 'UpdateCorporateDetailsModal',
          name: $t('offers.details.offers_information_section.corporate_gift_program.update.title'),
          action: () => this.openUpdateCorporateDetailsModal(),
          isAvailable: () => this.canManageOffer() && !this.isBonusOffer,
          isDisabled: () => !this.canManageOffer() && this.isBonusOffer,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('offers', ['offer']),
    ...mapGetters('ui', ['currentLocale']),
    hasAutomatedFeesPaymentMethod() {
      return _.get(this, 'offer.fee_payment_method') === 'monthly_automated_pad';
    },
    offerAvailabilityEndDate() {
      const endDate = this.offer.availability.end_date;
      return endDate ? endDate + ' ' + this.offer.availability.end_time : null;
    },
    userPaymentLimit() {
      return this.offer.incentive.bonus_percent && this.offer.incentive.user_inventory_limit
        ? adjustValueForMinStep(
            this.offer.incentive.minimum_payment,
            this.offer.incentive.step,
            this.offer.incentive.user_inventory_limit / (this.offer.incentive.bonus_percent / 100),
          )
        : 0;
    },
    publisherPaymentLimit() {
      if (this.offer.type === 'bonus') {
        return this.offer.incentive.bonus_percent && this.offer.availability.publisher_default_inventory_limit
          ? adjustValueForMinStep(
              this.offer.incentive.minimum_payment,
              this.offer.incentive.step,
              this.offer.availability.publisher_default_inventory_limit / (this.offer.incentive.bonus_percent / 100),
            )
          : 0;
      } else {
        if (!this.offer.availability) {
          return 0;
        }
        const publisherInventoryLimit = parseInt(this.offer.availability.publisher_default_inventory_limit);
        const reward = parseInt(this.offer.incentive.bonus_amount);
        const contribution = parseInt(this.offer.incentive.payment_amount);
        if (!reward && !contribution) {
          return 0;
        }
        return publisherInventoryLimit / (reward / (reward + contribution));
      }
    },
    offerTypeWithMeta() {
      const type = this.$t(`offers.types.${this.offer.type || 'none'}`);

      if (this.offer.type === 'bonus' && _.get(this.offer, 'features.restricted_to_members', false)) {
        return `${type} (${this.$t(`offers.details.offers_information_section.restricted_to_members`)})`;
      }

      if (this.offer.type === 'gift_certificate') {
        if (_.get(this.offer, 'corporate.enabled', false) && _.get(this.offer, 'consumer.enabled', false)) {
          return `${type} (${this.$t('offers.details.offers_information_section.corporate_gift_program.label')} / ${this.$t(
            'offers.details.offers_information_section.consumer_gift_program.label',
          )})`;
        }
        if (_.get(this.offer, 'corporate.enabled', false)) {
          return `${type} (${this.$t('offers.details.offers_information_section.corporate_gift_program.label')})`;
        }
        if (_.get(this.offer, 'consumer.enabled', false)) {
          return `${type} (${this.$t('offers.details.offers_information_section.consumer_gift_program.label')})`;
        }
        return type;
      }

      return type;
    },
    isBonusOffer() {
      return this.offer.type === 'bonus';
    },
    isGiftCertificateOffer() {
      return this.offer.type === 'gift_certificate';
    },
    consumerGiftProgramPurchaseLimits() {
      const minFormatted = this.$options.filters.dollarSignI18n(_.get(this.offer, 'consumer.purchase_limit.min', 0));
      const maxFormatted = this.$options.filters.dollarSignI18n(_.get(this.offer, 'consumer.purchase_limit.max', 0));

      return `${minFormatted} - ${maxFormatted}`;
    },
    corporateGiftProgramPurchaseFee() {
      const fixedFeeFormatted = this.applyCurrencyFilters(_.get(this.offer, 'corporate.fixed_fee', 0));

      return `${fixedFeeFormatted} + ${_.get(this.offer, 'corporate.percent_fee', 0)}%`;
    },
  },
  methods: {
    applyCurrencyFilters(value) {
      return this.$options.filters.dollarSignI18n(this.$options.filters.currency(value));
    },
    showActionBtnVoucher() {
      return this.canManageOffer();
    },
    openExtendOfferVoucherModal() {
      this.showExtendOfferVoucherModal = true;
    },
    closeExtendOfferVoucherModal() {
      this.showExtendOfferVoucherModal = false;
    },
    openUpdateBonusOffersModal() {
      this.showUpdateBonusOffersModal = true;
    },
    closeUpdateBonusOffersModal() {
      this.showUpdateBonusOffersModal = false;
    },
    async updateBonusOffersSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.details.offers_information_section.update_action.success' });
      this.closeUpdateBonusOffersModal();
    },
    async extendOfferVoucherSuccess() {
      // await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.details.offers_information_section.update_action.success' });
      this.closeExtendOfferVoucherModal();
    },
    openUpdateGiftCertificateOffersModal() {
      this.showUpdateGiftCertificateOffersModal = true;
    },
    closeUpdateGiftCertificateOffersModal() {
      this.showUpdateGiftCertificateOffersModal = false;
    },
    openUpdateConsumerDetailsModal() {
      this.showUpdateConsumerDetailsModal = true;
    },
    closeUpdateConsumerDetailsModal() {
      this.showUpdateConsumerDetailsModal = false;
    },
    openUpdateCorporateDetailsModal() {
      this.showUpdateCorporateDetailsModal = true;
    },
    closeUpdateCorporateDetailsModal() {
      this.showUpdateCorporateDetailsModal = false;
    },
    async updateGiftCertificateOffersSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.details.offers_information_section.update_action.success' });
      this.closeUpdateGiftCertificateOffersModal();
    },
    async updateConsumerDetailsSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.details.offers_information_section.update_action.success' });
      this.closeUpdateConsumerDetailsModal();
    },
    async updateCorporateDetailsSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.details.offers_information_section.update_action.success' });
      this.closeUpdateCorporateDetailsModal();
    },
    showUpdateOffersFeature() {
      return this.canManageOffer();
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../../styles/variables/custom-variables.styl';

h5 {
  color: $hc-blue;

  &.sub-title {
    color: $font-color;
  }
}
</style>
