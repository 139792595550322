import { render, staticRenderFns } from "./LayoutNotes.vue?vue&type=template&id=7b796000&scoped=true"
import script from "./LayoutNotes.vue?vue&type=script&lang=js"
export * from "./LayoutNotes.vue?vue&type=script&lang=js"
import style0 from "./LayoutNotes.vue?vue&type=style&index=0&id=7b796000&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b796000",
  null
  
)

export default component.exports