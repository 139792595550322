import Vue from 'vue';

const service = {
  async getVoucher(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/vouchers/${id}`);
    return response.data.data;
  },
  async createBonusVoucher(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/vouchers/bonus`, payload);
    return response.data.data;
  },
  async listVouchers({ keyword, partner_id, offer_id, activation_code, page, limit }) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    if (partner_id) {
      params.partner_id = partner_id;
    }
    if (offer_id) {
      params.offer_id = offer_id;
    }

    if (activation_code) {
      params.activation_code = activation_code;
    }

    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/vouchers', page, limit, params)}`,
    );
    return response.data.data;
  },
  async refundVoucher(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/vouchers/${id}/refund`, payload);
    return response.data.data;
  },
  async refundVoucherOrder(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/vouchers/${id}/refund-order`, payload);
    return response.data.data;
  },
  async extendVoucherExpiration(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/vouchers/${id}/extend-expiration`, payload);
    return response.data.data;
  },
  async applyVoucherDormancyExtension(id, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/vouchers/${id}/apply-dormancy-extension`,
      payload,
    );
    return response.data.data;
  },
  async transferVoucher(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/vouchers/${id}/transfer`, payload);
    return response.data.data;
  },
  async cancelVoucher(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/vouchers/${id}/cancel`, payload);
    return response.data.data;
  },
  async forceClose(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/vouchers/${id}/force-close`, payload);
    return response.data.data;
  },
  async listVoucherOperations(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/vouchers/${id}/operations`, page, limit)}`,
    );
    return response.data.data.items;
  },
  async listVouchersByAccountId(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/accounts/${id}/vouchers`, page, limit)}`,
    );
    return response.data.data;
  },
  async listVouchersByInstantCardPublicToken(public_token, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v1/instant-cards/${public_token}/vouchers`, page, limit)}`,
    );
    return response.data.data;
  },
  async listVouchersByOffer(offerId, page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/offers/${offerId}/vouchers`, page, limit, params)}`,
    );
    return response.data.data;
  },
  async unloadVoucher(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/vouchers/${id}/unload`);
    return response.data.data;
  },
  async loadVoucher(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/vouchers/${id}/load`, payload);
    return response.data.data;
  },

  async adjustVoucherBalance(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/vouchers/${id}/adjust-balances`, payload);
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, { keyword, partner_id, offer_id, activation_code } = {}) {
  let path = startPath;
  if (page || limit || keyword || partner_id || offer_id) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (partner_id) {
    queryStrings.push(['partner_id', partner_id]);
  }
  if (offer_id) {
    queryStrings.push(['offer_id', offer_id]);
  }
  if (activation_code) {
    queryStrings.push(['activation_code', activation_code]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
