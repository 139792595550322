<template>
  <v-flex class="search-offers-form">
    <h1 hidden>{{ $t('offers.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap class="form-search--main-wrap-v2">
          <v-flex class="form-search--inputs-zone-v2">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('offers.form.total_results_message')"
              :label="$t('offers.form.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('search')"
              data-test="form-offers-keyword"
            />
            <h-autocomplete
              v-model="partner_id"
              no-filter
              clearable
              :label="$t('offers.form.partner')"
              :error-messages="errors.collect('partner_id')"
              name="partner_id"
              data-vv-name="partner_id"
              data-vv-as="partner_id"
              search-action="partner/autocompleteSearch"
              data-test="form-offers-partner"
            >
            </h-autocomplete>
            <h-autocomplete
              v-model="owner_business_id"
              no-filter
              clearable
              :label="$t('offers.form.owner_business')"
              :error-messages="errors.collect('owner')"
              name="owner"
              data-vv-name="owner"
              data-vv-as="owner"
              search-action="business/autocompleteSearch"
              data-test="form-offers-owner"
            >
            </h-autocomplete>
            <v-select
              name="status"
              v-model="status"
              :error-messages="errors.collect('status')"
              data-vv-name="status"
              :data-vv-as="$t('offers.form.status')"
              :items="statusOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('offers.form.status')"
              data-test="form-offers-status"
            ></v-select>
            <v-select
              name="type"
              v-model="type"
              :error-messages="errors.collect('type')"
              data-vv-name="type"
              :data-vv-as="$t('offers.form.type')"
              :items="typeOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('offers.form.type')"
              data-test="form-offers-type"
            ></v-select>
            <v-select
              name="visibility"
              v-model="visibility"
              :error-messages="errors.collect('visibility')"
              data-vv-name="visibility"
              :data-vv-as="$t('offers.form.visibility')"
              :items="visibilityOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('offers.form.visibility')"
              data-test="form-offers-visibility"
            ></v-select>
            <button-primary class="offers--btn-search" :text="$t('offers.form.submit')" :loading="working" @click="submit" type="submit" />
          </v-flex>

          <v-flex class="form-search--actions-zone-v2">
            <button-primary
              data-test="offers--btn-add-bonus"
              id="offers--btn-add-bonus"
              class="offers-form--btn-add-bonus"
              :loading="working"
              :text="$t('offers.form.add_bonus')"
              @click="openCreateBonusOffersModal"
            />
            <button-primary
              data-test="offers--btn-add-gift"
              id="offers--btn-add-gift"
              class="offers-form--btn-add-gift"
              :loading="working"
              :text="$t('offers.form.add_gift')"
              @click="openCreateGiftCertificateOffersModal"
            />
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && offers.length === 0" :text="$t('offers.no_offers_found')" />
      <card v-if="dataReady && offers.length > 0" icon="fal fa-money-check-edit" :title="`${$t('offers.form.data_section.title')}`">
        <template slot="headerZoneRight">
          <label-text class="text-sm-center" :label="$t('offers.offer_count')" :value="listItemCount || '0'"></label-text>
        </template>
        <template slot="content">
          <offers-table :items="offers" />
        </template>
      </card>
    </v-flex>
    <modal-action :show-modal="showCreateBonusOffersModal"
      ><create-bonus-offers-action
        v-if="showCreateBonusOffersModal"
        @close="closeCreateBonusOffersModal"
        @created="createBonusOffersSuccess"
      ></create-bonus-offers-action
    ></modal-action>
    <modal-action :show-modal="showCreateGiftCertificateOffersModal"
      ><create-gift-certificate-offers-action
        v-if="showCreateGiftCertificateOffersModal"
        @close="closeCreateGiftCertificateOffersModal"
        @created="createGiftCertificateOffersSuccess"
      ></create-gift-certificate-offers-action
    ></modal-action>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import OffersTable from './OffersTable';
import CreateBonusOffersAction from '../actions/CreateBonusOffersAction';
import CreateGiftCertificateOffersAction from '../actions/CreateGiftCertificateOffersAction';
import _ from 'lodash';

export default {
  name: 'search-offers-form',
  mixins: [actionErrorTrackable, security],
  components: { OffersTable, CreateBonusOffersAction, CreateGiftCertificateOffersAction },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'offers/clearListOffers' });
  },
  data() {
    return {
      page: 1,
      dataReady: false,
      keyword: '',
      searched: '',
      partner_id: '',
      owner_business_id: '',
      status: '',
      type: '',
      visibility: '',
      consumer_enabled: null,
      corporate_enabled: null,
      showCreateBonusOffersModal: false,
      showCreateGiftCertificateOffersModal: false,
      statusOptions: [
        { name: this.$t('offers.status.draft'), value: 'draft' },
        { name: this.$t('offers.status.unready'), value: 'unready' },
        { name: this.$t('offers.status.coming_soon'), value: 'coming_soon' },
        { name: this.$t('offers.status.available'), value: 'available' },
        { name: this.$t('offers.status.sold_out'), value: 'sold_out' },
        { name: this.$t('offers.status.closed'), value: 'closed' },
      ],
      typeOptions: [
        { name: this.$t('offers.type.bonus'), value: 'bonus' },
        { name: this.$t('offers.type.gift_certificate'), value: 'gift_certificate' },
        { name: this.$t('offers.type.gift_certificate_corporate'), value: 'gift_certificate_corporate' },
        { name: this.$t('offers.type.gift_certificate_consumer'), value: 'gift_certificate_consumer' },
      ],
      visibilityOptions: [
        { name: this.$t('offers.visibility.private'), value: 'private' },
        { name: this.$t('offers.visibility.public'), value: 'public' },
      ],
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    this.partner_id = this.listPartnerId;
    this.owner_business_id = this.listOwnerBusinessId;
    this.status = this.listStatus;
    this.type = this.listType;
    this.visibility = this.listVisibility;

    if (this.routeHasQueryParams()) {
      this.page = 1;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.partner_id) {
      this.partner_id = this.$route.query.partner_id;
    }
    if (this.$route.query.owner_business_id) {
      this.owner_business_id = this.$route.query.owner_business_id;
    }
    if (this.$route.query.status) {
      this.status = this.$route.query.status;
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if (this.$route.query.consumer_enabled) {
      this.type = 'gift_certificate_consumer';
    }
    if (this.$route.query.corporate_enabled) {
      this.type = 'gift_certificate_corporate';
    }
    if (this.$route.query.visibility) {
      this.visibility = this.$route.query.visibility;
    }

    this.routeHasQueryParams() ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  computed: {
    ...mapGetters('offers', [
      'offers',
      'listKeyword',
      'listItemCount',
      'listPageNumber',
      'listPartnerId',
      'listOwnerBusinessId',
      'listStatus',
      'listType',
      'listVisibility',
    ]),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.partner_id = '';
      this.owner_business_id = '';
      this.status = '';
      this.type = '';
      this.visibility = '';
      this.$validator.reset();
    },
    routeHasQueryParams() {
      return (
        this.$route.query.keyword ||
        this.$route.query.partner_id ||
        this.$route.query.owner_business_id ||
        this.$route.query.status ||
        this.$route.query.type ||
        this.$route.query.visibility
      );
    },
    openCreateBonusOffersModal() {
      this.showCreateBonusOffersModal = true;
    },
    closeCreateBonusOffersModal() {
      this.showCreateBonusOffersModal = false;
    },
    openCreateGiftCertificateOffersModal() {
      this.showCreateGiftCertificateOffersModal = true;
    },
    closeCreateGiftCertificateOffersModal() {
      this.showCreateGiftCertificateOffersModal = false;
    },
    buildPayloadQueryString() {
      const payload = {};
      if (this.keyword) {
        payload.keyword = this.keyword;
      }
      if (this.partner_id) {
        payload.partner_id = this.partner_id;
      }
      if (this.owner_business_id) {
        payload.owner_business_id = this.owner_business_id;
      }
      switch (this.type) {
        case 'gift_certificate_corporate':
          payload.type = 'gift_certificate';
          payload.corporate_enabled = true;
          break;
        case 'gift_certificate_consumer':
          payload.type = 'gift_certificate';
          payload.consumer_enabled = true;
          break;
        default:
          payload.type = this.type;
      }
      if (this.status) {
        payload.status = this.status;
      }
      if (this.visibility) {
        payload.visibility = this.visibility;
      }

      return payload;
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        this.$router.replace({ query: this.buildPayloadQueryString() });
        await this.clearAllError();

        try {
          this.dataReady = false;
          const search = this.buildPayloadQueryString();
          search.page = page;
          await this.executeAction({ action: 'offers/listOffers' }, search);
        } finally {
          this.dataReady = true;
        }
      }
    },
    async createBonusOffersSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.create_action.success' });
      this.closeCreateBonusOffersModal();
    },
    async createGiftCertificateOffersSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.create_gift_action.success' });
      this.closeCreateGiftCertificateOffersModal();
    },
  },
};
</script>
