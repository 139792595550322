<template>
  <card
    :title="$t('instant_card_projects.details.project_information_section.title')"
    icon="fal fa-tasks-alt"
    :header-divider="false"
    id="project-information"
  >
    <template slot="headerZoneRight">
      <label-text :label="`${$t('instant_card_projects.details.project_information_section.id')}`" :value="project.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ project.id }}
            <button class="clipboard" v-clipboard:copy="project.id ? project.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('instant_card_projects.details.project_information_section.name')}`" :value="project.name"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('instant_card_projects.details.project_information_section.program')}`" :value="project.partner_id"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('instant_card_projects.details.project_information_section.structure.title')}`"
        :value="$t(`instant_card_projects.details.project_information_section.structure.options.${project.structure}`)"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('instant_card_projects.details.project_information_section.type.title')}`"
        :value="$t(`instant_card_projects.details.project_information_section.type.options.${project.type}`)"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('instant_card_projects.details.project_information_section.created')}`"
        :value="project.creation_date | formatShortDateInverted | orNotAvailable"
      ></label-text>
      <div v-if="showUpdateProjectFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showUpdateProjectFeature()" :label="`${$t('instant_card_projects.details.project_information_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openUpdateProjectModal">
          {{ $t('instant_card_projects.details.project_information_section.update_project') }}</link-secondary-action
        >
      </label-text>
      <modal-action :show-modal="showUpdateProjectModal"
        ><update-project-action
          v-if="showUpdateProjectModal"
          :project="project"
          @close="closeUpdateProjectModal"
          @updated="updateProjectSuccess"
        ></update-project-action
      ></modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UpdateProjectAction from '../actions/UpdateProjectAction';

import { mapGetters } from 'vuex';

export default {
  name: 'project-information',
  components: { UpdateProjectAction },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      showUpdateProjectModal: false,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('instantCardProject', ['project']),
  },
  methods: {
    openUpdateProjectModal() {
      this.showUpdateProjectModal = true;
    },
    closeUpdateProjectModal() {
      this.showUpdateProjectModal = false;
    },
    async updateProjectSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_projects.details.project_information_section.update_action.success' },
      );
      this.closeUpdateProjectModal();
    },
    showUpdateProjectFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
  },
};
</script>
