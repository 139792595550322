<template>
  <view-main title="page_title.application">
    <div slot="content">
      <four-levels-breadcrumbs
        v-if="application.account_id"
        :third-label="$t('navigation.applications')"
        :second-label="application.account_id"
        :second-to="{ name: 'user_details', params: { userId: application.account_id } }"
        :top-label="$t('navigation.users')"
        :top-to="{ name: 'users' }"
        :last-label="applicationId"
      />
      <two-levels-breadcrumbs v-else :top-label="$t('navigation.applications')" :last-label="applicationId" />
      <view-application v-if="dataReady && application" :application="application" :hash-tag="hashTag" :cards="cards" :kyc="kyc" />
      <alert-warning v-else-if="dataReady" :text="$t('application.no_application_found')"></alert-warning>
    </div>
  </view-main>
</template>

<script>
import { renderErrorTrackable, actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import ViewApplication from './ViewApplication.vue';

export default {
  name: 'application-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { ViewApplication },
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    applicationId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dataReady: false,
      hashTag: location.hash,
    };
  },
  async mounted() {
    if (this.applicationId) {
      await this.fetch();
    }
  },
  computed: {
    ...mapGetters('application', ['application', 'kyc']),
    ...mapGetters('card', ['cards']),
  },
  methods: {
    displayApplication() {
      this.dataReady = true;
    },
    waitForState() {
      this.dataReady = false;
    },
    async fetch() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        this.waitForState();

        await this.clearAllError();
        await this.executeAction({ action: 'application/getApplication' }, this.applicationId);

        if (this.application && this.application.card_public_token) {
          this.renderWithIssuedCard();
        } else {
          this.renderWithNoCard();
        }
      }
    },
    async renderWithIssuedCard() {
      await this.executeAction({ action: 'card/getCards' }, this.application.cardholder_key);
      this.displayApplication();
    },
    async renderWithNoCard() {
      await this.executeAction({ action: 'card/clearCard' });
      await this.executeAction({ action: 'card/clearCards' });
      this.displayApplication();
    },
  },
};
</script>
