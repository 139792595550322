<template>
  <card :title="$t('inventory.mouvements.title')" icon="">
    <template slot="content">
      <form @submit.prevent="submitListInventoriesByFilters" class="form-basic form-search">
        <v-layout align-center class="form-search--main-wrap-sub-module">
          <v-flex class="form-search--button-wrap-sub-module">
            <button-primary
              class="publishers-details.stores_section--btn-search"
              :text="$t('inventory.mouvements.form.submit')"
              :loading="working"
              type="submit"
              @click="submitListInventoriesByFilters"
            ></button-primary>
          </v-flex>
          <v-flex class="form-search--inputs-zone-sub-module" xs6>
            <v-select
              name="types"
              v-model="types"
              :error-messages="errors.collect('types')"
              data-vv-name="types"
              :data-vv-as="$t('inventory.mouvements.form.types')"
              :items="inventoryMouvementTypes"
              item-text="name"
              item-value="value"
              clearable
              multiple
              :label="$t('inventory.mouvements.form.types')"
              data-test="publishers-stores-status"
            ></v-select>
          </v-flex>
        </v-layout>
      </form>
      <inventory-mouvements-table v-if="inventoryMouvements.length" :items="inventoryMouvements"></inventory-mouvements-table>
      <label-none v-if="inventoryMouvements.length === 0">{{ $t('system.na') }}</label-none>
      <div class="text-xs-center">
        <v-pagination
          v-if="inventoryMouvementsPageCount > 1"
          class="apps-table--pagination"
          v-model="pagination"
          :length="inventoryMouvementsPageCount"
        ></v-pagination>
      </div>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import InventoryMouvementsTable from './InventoryMouvementsTable.vue';

export default {
  name: 'inventory-mouvements',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { InventoryMouvementsTable },
  data() {
    return {
      types: ['create', 'adjustment_debit', 'adjustment_credit'],
      inventoryMouvementTypes: [
        { name: this.$t('inventory.mouvements.types.create'), value: 'create' },
        { name: this.$t('inventory.mouvements.types.adjustment_debit'), value: 'adjustment_debit' },
        { name: this.$t('inventory.mouvements.types.adjustment_credit'), value: 'adjustment_credit' },
        { name: this.$t('inventory.mouvements.types.voucher_debit'), value: 'voucher_debit' },
        { name: this.$t('inventory.mouvements.types.voucher_credit'), value: 'voucher_credit' },
        { name: this.$t('inventory.mouvements.types.activation_code_debit'), value: 'activation_code_debit' },
        { name: this.$t('inventory.mouvements.types.activation_code_credit'), value: 'activation_code_credit' },
        { name: this.$t('inventory.mouvements.types.gift_certificate_purchase_fees'), value: 'gift_certificate_purchase_fees' },
        { name: this.$t('inventory.mouvements.types.purchase_debit'), value: 'purchase_debit' },
      ],
      pagination: 1,
    };
  },
  computed: {
    ...mapGetters('inventory', [
      'inventory',
      'inventoryMouvements',
      'inventoryMouvementsPageCount',
      'inventoryMouvementsPageNumber',
      'inventoryMouvementsItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async submitListInventoriesByFilters(event) {
      return this.fetchInventoryMouvements(1);
    },
    async fetchInventoryMouvements(page) {
      const pageNumber = page || this.inventoryMouvementsPageNumber;
      await this.executeAction({ action: 'inventory/listInventoryMouvements' }, { id: this.inventory.id, types: this.types, page: pageNumber });
    },
  },
  watch: {
    async inventory() {
      return this.fetchInventoryMouvements();
    },
    inventoryMouvementsPageNumber() {
      this.pagination = this.inventoryMouvementsPageNumber;
    },
    async pagination(newValue) {
      return this.fetchInventoryMouvements(newValue);
    },
  },
};
</script>
