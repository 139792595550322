<template>
  <view-main title="page_title.user_details">
    <two-levels-breadcrumbs slot="content" :top-label="$t('navigation.users')" :to="{ name: 'users' }" :last-label="account.id" />
    <user-deactivate-alert slot="content" />
    <user-information slot="content" />
    <user-my-cash slot="content" />
    <user-vouchers slot="content" />
    <user-cards slot="content" />
    <user-instant-cards slot="content" />
    <user-consumer-gift-card-orders slot="content" />
    <user-memberships slot="content" />
    <user-subscription slot="content" />
    <user-card-applications slot="content" />
    <user-aml-profile slot="content" v-if="canManageAmlProgram()" />
    <user-aml-notes slot="content" v-if="canManageAmlProgram()" />
    <user-accesses slot="content" />
    <user-notes slot="content" />
  </view-main>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, renderErrorTrackable, security } from '@/mixins';

import UserVouchers from '@/components/user/detail/UserVouchers.vue';
import UserAmlProfile from '@/components/user/detail/UserAmlProfile.vue';
import UserInformation from '@/components/user/detail/UserInformation.vue';
import UserSubscription from '@/components/user/detail/UserSubscriptions.vue';
import UserCardApplications from '@/components/user/detail/UserCardApplications';
import UserCards from '@/components/user/detail/UserCards';
import UserInstantCards from '@/components/user/detail/UserInstantCards';
import UserConsumerGiftCardOrders from '@/components/user/detail/UserConsumerGiftCardOrders';
import UserMemberships from '@/components/user/detail/UserMemberships';
import UserAccesses from '@/components/user/detail//UserAccesses';
import UserDeactivateAlert from '@/components/user/detail/components/UserDeactivateAlert.vue';
import UserMyCash from '@/components/user/detail/UserMyCash';
import UserNotes from '@/components/user/detail/UserNotes';
import UserAmlNotes from '@/components/user/detail/UserAmlNotes';

export default {
  name: 'user-details-view',

  mixins: [security, renderErrorTrackable, actionErrorTrackable],

  components: {
    UserAmlProfile,
    UserMyCash,
    UserInformation,
    UserVouchers,
    UserSubscription,
    UserCardApplications,
    UserCards,
    UserInstantCards,
    UserConsumerGiftCardOrders,
    UserMemberships,
    UserAccesses,
    UserDeactivateAlert,
    UserNotes,
    UserAmlNotes,
  },

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('account', ['account']),
  },

  async mounted() {
    await this.fetch();
  },

  watch: {
    async userId() {
      return this.fetch();
    },
  },

  methods: {
    async fetch() {
      const account = await this.executeAction({ action: 'account/getAccount' }, this.userId);
      if (!account) {
        this.$router.replace({ name: 'notFound' });
      }
    },
    canManageAmlProgram() {
      return this.hasPermission('manage_aml_program');
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../styles/variables/custom-variables.styl';
</style>
