import Vue from 'vue';

const service = {
  async searchOffers(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/offers/search`, page, limit, params)}`,
    );
    return response.data.data;
  },
  async addOfferTrackingLink(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${id}/tracking-links`, payload);
    return response.data;
  },
  async listOfferTrackingLinks(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/offers/${id}/tracking-links`, page, limit)}`,
    );
    return response.data.data;
  },
  async enableOfferTrackingLink(id, offer_id) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/tracking-links/${id}/enable`,
    );
    return response.data;
  },
  async disableOfferTrackingLink(id, offer_id) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/tracking-links/${id}/disable`,
    );
    return response.data;
  },
  async generateQrCodeImage(id, offer_id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/tracking-links/${id}/generate`,
      payload,
    );
    return response.data.data.qrcode;
  },
  async addStoresFromOffer(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${id}/stores`, payload);
    return response.data;
  },
  async getOfferStatistics(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${id}/statistics`);
    return response.data.data;
  },
  async listOfferAccessesByEmail(email) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/accesses?email=${encodeURIComponent(email)}`,
    );
    return response.data.data.items;
  },
  async listInstantCardProjectsByOffer(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/offers/${id}/instant-card-projects`, page, limit)}`,
    );
    return response.data.data;
  },
  async extendOfferVoucherExpiration(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${id}/extend-expiration`, payload);
    return response.data.data;
  },
  async getOffer(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${id}`);
    return response.data.data;
  },
  async createBonusOffer(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/bonus`, payload);
    return response.data.data;
  },
  async createGiftCertificateOffer(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/gift-certificate`, payload);
    return response.data.data;
  },
  async updateBonusOffer(id, params) {
    const payload = params;
    delete payload.system_status;
    delete payload.external_id;
    delete payload.corporate;
    delete payload.activation_url_raw;
    delete payload.activation_url_friendly;
    delete payload.activation_url_short;
    delete payload.consumer_url_raw;
    delete payload.consumer_url_friendly;
    delete payload.consumer_url_short;
    delete payload.corpo_url_raw;
    delete payload.corpo_url_friendly;
    delete payload.corpo_url_short;

    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/bonus/${id}`, payload);
    return response.data.data;
  },
  async updateGiftCertificateOffer(id, params) {
    const payload = params;
    delete payload.system_status;
    delete payload.external_id;
    delete payload.corporate;
    delete payload.activation_url_raw;
    delete payload.activation_url_friendly;
    delete payload.activation_url_short;
    delete payload.consumer_url_raw;
    delete payload.consumer_url_friendly;
    delete payload.consumer_url_short;
    delete payload.corpo_url_raw;
    delete payload.corpo_url_friendly;
    delete payload.corpo_url_short;
    delete payload.features.restricted_to_members;

    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/gift-certificate/${id}`, payload);

    return response.data.data;
  },
  async listOffers({
    keyword,
    partner_id,
    business_id,
    owner_business_id,
    status,
    type,
    is_single_publisher,
    visibility,
    corporate_enabled,
    consumer_enabled,
    page,
    limit,
  }) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    if (partner_id) {
      params.partner_id = partner_id;
    }
    if (type) {
      params.type = type;
    }
    if (is_single_publisher) {
      params.is_single_publisher = is_single_publisher;
    }
    if (business_id) {
      params.business_id = business_id;
    }
    if (owner_business_id) {
      params.owner_business_id = owner_business_id;
    }
    if (status) {
      params.status = status;
    }
    if (visibility) {
      params.visibility = visibility;
    }
    if (corporate_enabled) {
      params.corporate_enabled = corporate_enabled;
    }
    if (consumer_enabled) {
      params.consumer_enabled = consumer_enabled;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/offers', page, limit, params)}`,
    );
    return response.data.data;
  },
  async createOfferNote(offer_id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/notes`, payload);
    return response.data.data;
  },
  async deleteOfferNote(offer_id, note_id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/notes/${note_id}/delete`,
      payload,
    );
    return response.data.data;
  },
  async listOfferNotes(page, limit, offer_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/offers/${offer_id}/notes`, page, limit)}`,
    );
    return response.data.data;
  },
  async addOfferEmailAccess({ offer_id, email }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/email-access`, { email });
    return response.data;
  },
  async removeOfferEmailAccess(id) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/email-access/${id}`);
    return response.data.data;
  },
  async listOfferAccess(offer_id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/email-access`);
    return response.data.data.items;
  },
  async addPublisherToOffer(offer_id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/publishers`, payload);
    return response.data.data;
  },
  async removePublisherFromOffer(offer_id, publisher_id) {
    const response = await Vue.prototype.$axios.delete(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/publishers/${publisher_id}`,
    );
    return response.data.data;
  },
  async deactivateOfferPublisher(offer_id, publisher_id) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/publishers/${publisher_id}/deactivate`,
    );
    return response.data.data;
  },
  async activateOfferPublisher(offer_id, publisher_id) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/publishers/${publisher_id}/activate`,
    );
    return response.data.data;
  },
  async updateOfferPublisher(offer_id, publisher_id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/publishers/${publisher_id}`,
      payload,
    );
    return response.data.data;
  },
  async listOfferWebhookListeners(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/offers/${id}/webhook-listeners`, page, limit)}`,
    );
    return response.data.data;
  },
  async removeOfferWebhookListener(offerId, webhookListenerId) {
    const response = await Vue.prototype.$axios.delete(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offerId}/webhook-listeners/${webhookListenerId}`,
    );
    return response;
  },
  async addOfferWebhookListener(offerId, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offerId}/webhook-listeners`, payload);
    return response;
  },
  async listOfferPublishersByOffer(offer_id, keyword, status, category, readiness, page, limit) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    if (status) {
      params.status = status;
    }
    if (category) {
      params.category = category;
    }
    if (readiness) {
      params.readiness = readiness;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/offers/${offer_id}/publishers`, page, limit, params)}`,
    );
    return response.data.data;
  },
  async searchOfferPublishersByOffer(offer_id, page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/offers/${offer_id}/publishers/search`, page, limit, params)}`,
    );
    return response.data.data;
  },
  async updateInventoryLimitForAllOfferPublishers(offer_id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/publishers/inventory-limits`,
      payload,
    );
    return response.data.data;
  },
  async updateOfferConsumerDetails({ offer_id, consumer }) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/consumer`, consumer);

    return response.data.data;
  },
  async updateOfferCorporateDetails({ offer_id, corporate }) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offer_id}/corporate`, corporate);

    return response.data.data;
  },
};

function generatePath(
  startPath,
  page,
  limit,
  {
    keyword,
    partner_id,
    business_id,
    owner_business_id,
    status,
    type,
    is_single_publisher,
    category,
    readiness,
    visibility,
    corporate_enabled,
    consumer_enabled,
    sort_order,
  } = {},
) {
  const ManagedQueryParams = {
    page,
    limit,
    keyword,
    partner_id,
    business_id,
    owner_business_id,
    status,
    type,
    is_single_publisher,
    category,
    readiness,
    visibility,
    corporate_enabled,
    consumer_enabled,
    sort_order,
  };

  const queryStrings = [];
  Object.keys(ManagedQueryParams).forEach((queryFieldName) => {
    const queryFieldValue = ManagedQueryParams[queryFieldName];
    if (queryFieldValue) {
      queryStrings.push([queryFieldName, queryFieldValue]);
    }
  });

  let path = startPath;

  if (queryStrings.length > 0) {
    path += '?';
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
