<template>
  <card-modal
    icon=""
    :title="$t('instant_card_batches.update_tracking_action.title')"
    context="updateBatch"
    @submit="submit"
    @cancel="cancel"
    cancel_text="instant_card_batches.update_tracking_action.button_cancel"
    submit_text="instant_card_batches.update_tracking_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <h-autocomplete
          v-model="currentBatch.business_id"
          :label="$t('purse.load_open_purse_from_business.fields.business_id') + '*'"
          v-validate="'required'"
          :error-messages="errors.collect('business_id')"
          name="business_id"
          data-vv-name="business_id"
          data-vv-as="business_id"
          search-action="business/autocompleteSearch"
          data-test="load-open-purse--business"
          no-filter
        >
        </h-autocomplete>
        <h-autocomplete
          v-model="currentBatch.establishment_id"
          :label="$t('instant_card_batches.update_tracking_action.establishment')"
          :error-messages="errors.collect('establishment_id')"
          :alternate-text="getEstablishmentLabel"
          alternate-id="business_id"
          name="establishment_id"
          data-vv-name="establishment_id"
          data-vv-as="establishment_id"
          search-action="space/autocompleteSearchSpacePartnerEstablishments"
          data-test="form-instant-card-batches--establishment"
          no-filter
          clearable
        >
        </h-autocomplete>
        <v-text-field
          autofocus
          :label="$t('instant_card_batches.update_tracking_action.tracking_number')"
          name="tracking_number"
          v-validate="'max:100'"
          :error-messages="errors.collect('tracking_number')"
          v-model="currentBatch.delivery.tracking_number"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('instant_card_batches.update_tracking_action.tracking_number')"
          data-test="update-batch-tracking--tracking-number"
          required
        ></v-text-field>
        <v-text-field
          :label="$t('instant_card_batches.update_tracking_action.attn')"
          name="attn"
          v-validate="'max:50'"
          :error-messages="errors.collect('attn')"
          v-model="currentBatch.delivery.attn"
          data-vv-name="name"
          maxlength="50"
          :data-vv-as="$t('instant_card_batches.update_tracking_action.attn')"
          data-test="update-batch-tracking--attn"
          required
        ></v-text-field>
        <v-text-field
          :label="$t('instant_card_batches.update_tracking_action.phone')"
          name="phone"
          v-validate="'max:20'"
          :error-messages="errors.collect('phone')"
          v-model="currentBatch.delivery.phone"
          data-vv-name="phone"
          maxlength="20"
          :data-vv-as="$t('instant_card_batches.update_tracking_action.phone')"
          data-test="update-batch-tracking--phone"
          required
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'update-batch-tracking-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    batch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentBatch: { delivery: {} },
    };
  },
  async mounted() {
    this.currentBatch = _.cloneDeep(this.batch);
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.currentBatch = { delivery: {} };
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      return {
        id: this.currentBatch.id,
        business_id: this.currentBatch.business_id,
        establishment_id: this.currentBatch.establishment_id,
        tracking_number: this.currentBatch.delivery.tracking_number ? this.currentBatch.delivery.tracking_number : null,
        attn: this.currentBatch.delivery.attn ? this.currentBatch.delivery.attn : null,
        phone: this.currentBatch.delivery.phone ? this.currentBatch.delivery.phone : null,
      };
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardBatch/updateBatchTracking',
            name: 'updateBatch',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    getEstablishmentLabel(establishment) {
      return `${establishment.partner_id} - ${establishment.business_name}`;
    },
  },
};
</script>
