import datetimeFactory from 'datetime-factory';
import moment from 'moment';
import Vue from 'vue';
import Maska from 'maska';
import App from './App.vue';
import filters from './filters';
import directives from './directives';
import './fontawsome-pro/js/all';

import pluginAxios from './plugins/axios';
import pluginCookies from './plugins/cookies';
import pluginI18n from './plugins/i18n';
import pluginVeeValidate from './plugins/vee-validate';
import pluginVueClipboard from './plugins/vue-clipboard';
import pluginVueHighlight from './plugins/vue-highlight';
import pluginVuetify from './plugins/vuetify';
import pluginGoogleMaps from './plugins/google-maps';

import router from './router';
import './services';
import store from './store/store';
import config from '@/config';

datetimeFactory.configure({
  factory: function () {
    moment.locale(app.i18n.locale);
    return moment();
  },
});

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
  console.log(err); // eslint-disable-line no-console
  alert(`Caught an error : ${err}`); // eslint-disable-line no-alert
  rg4js('send', {
    error: err,
    customData: [{ info }],
  });
};

const app = {
  router,
  store,
  render: (h) => h(App),
};

const plugins = [];

const pluginKeycloak = require('./plugins/keycloak').default;
plugins.push(pluginKeycloak);

plugins.push(pluginCookies);
plugins.push(pluginVuetify);
plugins.push(pluginI18n);
plugins.push(pluginAxios);
plugins.push(pluginVeeValidate);
plugins.push(pluginVueHighlight);
plugins.push(pluginVueClipboard);
plugins.push(pluginGoogleMaps);
plugins.push(filters);
plugins.push(directives);

Vue.use(Maska);

plugins.forEach((plugin) =>
  plugin({
    app,
    router,
    store,
    Vue,
    startAppFn: vueAuth,
  }),
);

if (!config.oidc.keycloak) {
  vueAuth();
}

function vueAuth() {
  Vue.prototype.$auth
    .getUser()
    .then((user) => {
      if (user) {
        return store.dispatch('security/login', user);
      }
      return store.dispatch('security/logout');
    })
    .catch((err) => {
      console.log('Error from Hello initialization'); // eslint-disable-line no-console
      console.log(err); // eslint-disable-line no-console
      return store.dispatch('security/logout');
    })
    .catch(() => {
      // Silence error if already logout
    })
    .then(() => {
      Vue.config.errorHandler = (error) => {
        try {
          store.dispatch('ui/addError', { contextName: global, error });
        } catch (error) {
          alert(`Caught an error : ${error}`); // eslint-disable-line no-alert
        }
      };
      return new Vue(app).$mount('#app');
    });
}
