<template>
  <v-dialog v-if="avoidRendering ? showDialog : true" v-model="showDialog" persistent width="360px">
    <v-card>
      <v-card-title class="modal-card--card-title text-xs-center">
        <v-layout justify-center>
          <v-icon color="primary" data-fa-transform="down-1">far fa-exclamation-triangle</v-icon>
          <h3 class="primary--text">{{ title }}</h3>
        </v-layout>
      </v-card-title>

      <v-card-text>
        <error :context="context" />
        <p class="modal-confirm--text text-xs-center" v-html="text"></p>
      </v-card-text>

      <v-card-actions>
        <v-layout justify-center>
          <button-modal-cancel :text="$t(cancel_text)" @click="cancel" class="modal-confirm--cancel-button"></button-modal-cancel>
          <button-error @click="submit" :text="$t(submit_text)" :loading="submit_working" class="modal-confirm--submit-button"></button-error>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import error from '@/mixins/action-error-trackable';

export default {
  name: 'modal-confirm',
  mixins: [error],
  props: {
    title: {
      type: String,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    cancel_text: {
      type: String,
      required: true,
    },
    submit_text: {
      type: String,
      required: false,
    },
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    submit_working: {
      type: Boolean,
      required: false,
      default: false,
    },
    avoidRendering: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      showDialog: this.showModal,
    };
  },
  watch: {
    showModal: function(newVal) {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      this.showDialog = newVal;
    },
    showDialog: function(newVal) {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      if (newVal === false) {
        this.$emit('close');
      }
    },
  },
  methods: {
    clear() {
      this.clearError(this.context);
    },
    submit() {
      this.clear();
      this.$emit('submit');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
