<template>
  <view-main title="page_title.card">
    <div slot="content">
      <four-levels-breadcrumbs
        v-if="cardAccountId"
        :third-label="$t('navigation.cards')"
        :second-label="cardAccountId"
        :second-to="{ name: 'user_details', params: { userId: cardAccountId } }"
        :top-label="$t('navigation.users')"
        :top-to="{ name: 'users' }"
        :last-label="cardPublicToken"
      />
      <two-levels-breadcrumbs v-else :top-label="$t('navigation.cards')" :last-label="cardPublicToken" />
      <view-card
        v-if="dataReady && card"
        :card="card"
        :cards="cards"
        :card-public-token="cardPublicToken"
        :application="application"
        :aml-status="amlStatus"
        :hash-tag="hashTag"
        :aml-status-logs-and-assessment-checks="amlStatusLogsAndAssessmentChecks"
        :partner-card-renew-package="partnerCardRenewPackage"
        @card-change="cardChange"
      />
      <alert-warning v-else-if="dataReady" :text="$t('card.no_card_found')"></alert-warning>
    </div>
  </view-main>
</template>

<script>
import { renderErrorTrackable, actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import ViewCard from './ViewCard';

export default {
  name: 'card-view',
  mixins: [renderErrorTrackable, actionErrorTrackable, security],
  components: { ViewCard },
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    cardPublicToken: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dataReady: false,
      hashTag: location.hash,
      cardAccountId: null,
      partnerCardRenewPackage: null,
    };
  },
  watch: {
    async cardPublicToken() {
      await this.fetch();
    },
  },
  async mounted() {
    if (this.cardPublicToken) {
      await this.fetch();
    }
  },
  computed: {
    ...mapGetters('card', ['card', 'cards']),
    ...mapGetters('application', ['application']),
    ...mapGetters('aml', ['amlStatus', 'amlStatusLogsAndAssessmentChecks']),
    classicProgram() {
      return this.card && this.card.card_program_type === 'gpr';
    },
  },
  methods: {
    clear() {
      this.$validator.reset();
      this.dataReady = false;
    },
    displayCard() {
      this.dataReady = true;
    },
    waitForState() {
      this.dataReady = false;
    },
    cardChange(cardPublicToken) {
      this.$router.push({ name: 'card', params: { cardPublicToken: cardPublicToken } });
    },
    async fetch() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        this.waitForState();

        await this.clearAllError();
        this.cardAccountId = null;
        await this.executeAction({ action: 'card/getCard' }, this.cardPublicToken);
        if (this.card) {
          this.cardAccountId = this.card.account_id;
          await this.executeAction({ action: 'card/getCards' }, this.card.cardholder.key);
          if (this.card.cardholder.card_application_id) {
            await this.executeAction({ action: 'application/getApplication' }, this.card.cardholder.card_application_id);
          }

          this.partnerCardRenewPackage = await this.executeAction(
            { action: 'partner/getPartnerCardRenewPackage' },
            {
              card_program_type: this.card.card_program_type,
              partnerId: this.card.partner_id,
              cardholderLanguage: this.card.cardholder.language,
            },
          );
        }

        if (this.card && this.classicProgram && this.canManageAmlProgram()) {
          await this.executeAction({ name: 'dev/null', action: 'aml/getAmlStatus' }, this.cardPublicToken);

          if (this.amlStatus) {
            await this.executeAction({ action: 'aml/getAmlStatusLogsAndAssessmentChecks' }, this.cardPublicToken);
          }
        }
        this.displayCard();
      }
    },
  },
};
</script>
