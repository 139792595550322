import Vue from 'vue';

const service = {
  async setRiskRating(publicToken, risk_rating) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/cardholder/v1/cardholders/${publicToken}/risk-rating`, {
      risk_rating,
    });
    return response.data.data.risk_rating;
  },
};

export default service;
