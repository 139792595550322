<template>
  <div>
    <v-sheet>
      <div v-for="(item, index) in items" :key="index">
        <v-layout row :class="{ 'deleted-note': isDeleted(item) }">
          <v-flex xs10>
            <app-name :app_key="item.app_key" /><span> - </span>
            <span class="date" v-if="item.creation_date">{{ item.creation_date | formatDateAbbreviateMonth }}</span>
          </v-flex>

          <v-flex xs2 class="actions" v-if="!isDeleted(item)">
            <link-secondary-action slot="labelTextLink" @click="openDeleteNoteModal(item.id)"> {{ $t('note.delete') }}</link-secondary-action>
          </v-flex>
        </v-layout>

        <div class="content" :class="{ 'deleted-note': isDeleted(item) }">
          <div v-html="generateHtml(item.note)"></div>
          <label class="label-text--value red--text" v-if="isDeleted(item)">
            {{ $t('note.deleted_by') }}
            <inline-app-name :app_key="item.deleted.app_key" />
            ({{ item.deleted.deleted_at | formatDateHourMin }}):
            <span data-test="note--delete-reason">{{ item.deleted.reason }}</span>
          </label>
        </div>

        <v-divider style="margin-top: 1em;" v-if="!isLastNote(index)" />
      </div>
    </v-sheet>
  </div>
</template>

<script>
import _get from 'lodash/get';
import _ from 'lodash';

export default {
  name: 'layout-notes',
  props: {
    items: {
      type: Array,
      required: false,
    },
    deletedBy: {
      type: String,
      required: false,
    },
  },
  methods: {
    generateHtml(note) {
      let sentences = note.split('\n');
      return _.reduce(sentences, (acc, sentence) => `${acc}${sentence}<br>`, '');
    },
    isDeleted(note) {
      return _get(note, 'deleted.deleted_at', false);
    },
    isLastNote(index) {
      return index + 1 === this.items.length;
    },
    openDeleteNoteModal(noteId) {
      this.$emit('delete', noteId);
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../../styles/variables/custom-variables.styl';
.deleted-note {
  background-color: $deleted-background;
}
.app-name--wrap {
  display: inline;
}
.actions {
  text-align: right;
}
.date {
  color: $font-color-lighter;
}
</style>
