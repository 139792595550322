<template>
  <div class="card" v-if="account.status === AccountStatus.DEACTIVATED">
    <v-alert type="error" :value="true">
      <strong>{{
        $t('user.deactivated_message', {
          reason: account.deactivation_reason,
          date: this.$options.filters.formatShortDateInverted(account.deactivation_date),
          agent: appName,
        })
      }}</strong>
    </v-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AccountStatus from '@/enums/account-status';

export default {
  name: 'user-deactivate-alert',
  data() {
    return {
      appName: '?',
    };
  },
  watch: {
    async account() {
      if (this.account && this.account.status === AccountStatus.DEACTIVATED && this.account.deactivated_by_app_key) {
        const app = await this.getAppName(this.account.deactivated_by_app_key);
        this.appName = app ? app : '?';
      } else {
        this.appName = '?';
      }
    },
  },
  computed: {
    AccountStatus() {
      return AccountStatus;
    },
    ...mapGetters('account', ['account', 'accountId']),
  },
  methods: {
    ...mapActions('security', ['getAppName']),
  },
};
</script>
