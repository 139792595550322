<template>
  <card-modal
    icon=""
    :title="$t('instant_card_batches.approve_action.title')"
    context="approveBatch"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="instant_card_batches.approve_action.button_cancel"
    submit_text="instant_card_batches.approve_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <label-text :label="`${$t('instant_card_batches.approve_action.id')}`" :value="batch.id" />
        <label-text :label="`${$t('instant_card_batches.details.batch_information_section.project')}`" :value="batch.instant_card_project_name" />
        <label-text :label="`${$t('instant_card_batches.details.batch_information_section.card_package_id')}`" :value="batch.card_package_id" />
        <label-text :label="`${$t('instant_card_batches.details.batch_information_section.quantity')}`" :value="batch.quantity" />
        <label-text
          v-if="batch.delivery_address"
          :label="$t(`instant_card_batches.details.delivery_section.shipping_address`)"
          :value="fullAddress(batch.delivery_address)"
        />
        <label-text :label="`${$t('instant_card_batches.details.delivery_section.attn')}`" :value="batch.delivery.attn" />
        <label-text :label="`${$t('instant_card_batches.details.delivery_section.phone')}`" :value="batch.delivery.phone" />
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'approve-batch-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    batch: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.$validator.reset();
    },
    fullAddress(address) {
      return `${address.street}, ${address.city}, ${address.state}, ${address.country}, ${address.zip}`;
    },
    success(response) {
      this.clear();
      this.$emit('approved');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardBatch/approveBatch',
            name: 'approveBatch',
            success: this.success,
          },
          this.batch.id,
        );
      }
    },
  },
};
</script>
