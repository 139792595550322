<template>
  <v-alert class="error-banner" type="error" v-model="show" transition="scale-transition" icon="fa-exclamation-triangle">
    <v-layout v-if="error.status_code === 400" column>
      <h5 style="font-weight: bold;">{{ error.msg }}</h5>
      <div>Code: {{ error.response_code }}</div>
    </v-layout>
    <v-layout v-else column>
      <h2>{{ $t(`errors.systemError`) }} {{ text }}</h2>
      <span class="h6"> Status Code: {{ error.status_code }}</span> <span class="h6"> Type: {{ error.type }}</span>
      <span class="h6"> Code: {{ error.response_code }}</span> <span class="h6"> Message: {{ error.msg }}</span>
    </v-layout>
  </v-alert>
</template>

<script>
export default {
  name: 'alert-system-error',
  props: {
    error: {
      type: Object,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>
