<template>
  <card-modal
    icon=""
    :title="$t('offers.redeem_action.title')"
    context="redeemActivationCode"
    @submit="submit"
    @cancel="cancel"
    cancel_text="offers.redeem_action.cancel_text"
    submit_text="offers.redeem_action.submit_text"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-flex sm12 xs12>
          <h-autocomplete
            v-model="account_id"
            :label="$t('offers.redeem_action.user') + '*'"
            v-validate="'required'"
            :error-messages="errors.collect('user')"
            name="user"
            item-value="account_id"
            alternate-text="email"
            alternate-id="account_id"
            data-vv-name="user"
            data-vv-as="user"
            search-action="account/autocompleteSearch"
            :add-asterisk-to-keyword="true"
            data-test="redeem-activation-code--user"
          >
          </h-autocomplete>
        </v-flex>
        <v-flex sm12 xs12 v-if="account_id && loadableCards.length">
          <v-select
            name="card"
            v-model="cardholder_id"
            v-validate="'required'"
            :error-messages="errors.collect('card')"
            data-vv-name="card"
            :data-vv-as="$t(`offers.redeem_action.card`)"
            :items="loadableCardOptions"
            item-text="text"
            item-value="value"
            :label="$t(`offers.redeem_action.card`)"
          ></v-select>
        </v-flex>
        <v-flex sm12 xs12>
          <h-autocomplete
            v-model="activationCode.publisher_id"
            :label="$t('offers.redeem_action.publisher') + '*'"
            v-validate="'required'"
            :error-messages="errors.collect('publisher')"
            name="publisher"
            data-vv-name="publisher"
            data-vv-as="publisher"
            search-action="offers/autocompleteSearchOfferPublishersByOffer"
            :extra-search-params="{ offer_id: offerId }"
            data-test="redeem-activation-code--publisher"
          >
          </h-autocomplete>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import _ from 'lodash';

export default {
  name: 'redeem-activation-code-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offerId: {
      type: String,
      required: true,
    },
    activationCodeId: {
      type: String,
      required: true,
    },
    helloShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      activationCode: {},
      account_id: null,
      cardholder_id: null,
      loadableCardOptions: null,
    };
  },
  watch: {
    async helloShow(flag) {
      if (flag) {
        this.cardholder_id = null;
        await this.fetchOffer();
      }
    },
    async account_id(val) {
      await this.executeAction(
        {
          action: 'account/listLoadableCardsByAccountId',
          name: 'listLoadableCardsByAccountId',
        },
        val,
      );
    },
    loadableCards() {
      this.cardholder_id = null;
      this.loadableCardOptions = _.map(this.loadableCards, (card) => {
        card.text = `${card.public_token} (${card.card_package_id}) ${card.masked_pan}`;
        card.value = card.cardholder_id;
        return card;
      });
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('account', ['loadableCards']),
    ...mapGetters('offers', ['offer']),
  },
  methods: {
    clear() {
      this.activationCode = {};
      this.account_id = null;
      this.cardholder_id = null;
      this.loadableCardOptions = null;
      this.$validator.reset();
    },
    async fetchOffer() {
      return this.executeAction({ action: 'offers/getOffer' }, this.offerId);
    },
    success(response) {
      this.clear();
      this.$emit('created');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      const payload = Object.assign(this.activationCode, { id: this.activationCodeId, account_id: this.account_id });
      if (this.cardholder_id) {
        payload.cardholder_id = this.cardholder_id;
      }
      return payload;
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'activationCode/redeemActivationCode',
            name: 'redeemActivationCode',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>
