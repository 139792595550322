<template>
  <card
    :title="$t('instant_card_cards.details.card_information_section.title')"
    icon="fal fa-credit-card-blank"
    :header-divider="false"
    id="card-information"
  >
    <template slot="headerZoneRight">
      <label-text :label="`${$t('instant_card_cards.details.card_information_section.id')}`" :value="card.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ card.id }}
            <button class="clipboard" v-clipboard:copy="card.id ? card.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text :label="`${$t('instant_card_cards.details.card_information_section.public_token')}`" :value="card.public_token">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            <router-link v-if="card.status === 'active'" :to="{ name: 'card', params: { cardPublicToken: card.public_token } }">
              {{ card.public_token }}
            </router-link>
            <span v-else>{{ card.public_token }}</span>
            <button
              :class="{ clipboard: true, 'card-info-public-token-clipboard': card.status === 'active' ? true : false }"
              v-clipboard:copy="card.public_token"
            >
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text :label="`${$t('instant_card_cards.details.card_information_section.unique_number')}`" :value="card.unique_number"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text :label="`${$t('instant_card_cards.details.card_information_section.barcode')}`" :value="card.barcode"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text :label="`${$t('instant_card_cards.details.card_information_section.masked_pan')}`" :value="card.card_masked_pan">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ card.card_masked_pan }}
            <button class="clipboard" v-clipboard:copy="card.card_masked_pan ? card.card_masked_pan.slice(-4) : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text
        :label="`${$t('instant_card_cards.details.card_information_section.status')}`"
        :value="$t(`instant_card_cards.status.${card.status}`)"
        :class-array="[`instant-card-${card.status}`]"
      ></label-text>
      <label-text
        :label="`${$t('instant_card_cards.details.card_information_section.activation_enabled')}`"
        :value="$t(`boolean.${card.activation_enabled}`)"
      />
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text v-if="card.account_id" :label="$t(`instant_card_cards.details.card_information_section.user`)">
        <p slot="labelTextLink" class="label-text--value">
          <link-secondary-route
            class="hc-public-token"
            :can-access="canViewApplication()"
            :route-name="'user_details'"
            :route-params="{ userId: card.account_id }"
            >{{ card.account_id }}</link-secondary-route
          >
        </p>
      </label-text>
      <div v-if="card.account_id" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text
        :label="`${$t('instant_card_cards.details.card_information_section.date_created')}`"
        :value="card.creation_date | formatShortDateInverted | orNotAvailable"
      ></label-text>
      <div v-if="showActionBtn()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <link-secondary-action v-if="showActionBtn()" slot="labelTextLink">
        <button-secondary-actions :text="$t(`instant_card_cards.details.card_information_section.actions`)" :actions="actions">
        </button-secondary-actions>
      </link-secondary-action>

      <modal-action :show-modal="showActivateCardModal"
        ><activate-instant-card-action
          v-if="showActivateCardModal"
          :public-token="card.public_token"
          @close="closeActivateCardModal"
          @activated="activateCardSuccess"
        ></activate-instant-card-action
      ></modal-action>
      <modal-action :show-modal="showPauseCardModal"
        ><pause-instant-card-action
          v-if="showPauseCardModal"
          :public-token="card.public_token"
          @close="closePauseCardModal"
          @paused="pauseCardSuccess"
        ></pause-instant-card-action
      ></modal-action>
      <modal-action :show-modal="showResumeCardModal"
        ><resume-instant-card-action
          v-if="showResumeCardModal"
          :public-token="card.public_token"
          @close="closeResumeCardModal"
          @resumed="resumeCardSuccess"
        ></resume-instant-card-action
      ></modal-action>
      <modal-action :show-modal="showDestroyCardModal"
        ><destroy-instant-card-action
          v-if="showDestroyCardModal"
          :public-token="card.public_token"
          @close="closeDestroyCardModal"
          @destroyed="destroyCardSuccess"
        ></destroy-instant-card-action
      ></modal-action>
      <modal-confirm
        v-if="showPreActivateModal"
        :show-modal="showPreActivateModal"
        :title="$t(`instant_card_cards.details.pre_activate_action.confirm.title`)"
        context="preActivate"
        :text="$t(`instant_card_cards.details.pre_activate_action.confirm.text`, { cardName: `${card.id}` })"
        :cancel_text="`instant_card_cards.details.pre_activate_action.confirm.button_cancel`"
        :submit_text="`instant_card_cards.details.pre_activate_action.confirm.button_submit`"
        :submit_working="working"
        @submit="preActivate"
        @close="closePreActivateModal"
      ></modal-confirm>
      <modal-confirm
        v-if="showMarkAsNonPreActivatedModal"
        :show-modal="showMarkAsNonPreActivatedModal"
        :title="$t(`instant_card_cards.details.mark_as_non_pre_activated_action.confirm.title`)"
        context="markAsNonPreActivated"
        :text="$t(`instant_card_cards.details.mark_as_non_pre_activated_action.confirm.text`, { cardName: `${card.id}` })"
        :cancel_text="`instant_card_cards.details.mark_as_non_pre_activated_action.confirm.button_cancel`"
        :submit_text="`instant_card_cards.details.mark_as_non_pre_activated_action.confirm.button_submit`"
        :submit_working="working"
        @submit="markAsNonPreActivated"
        @close="closeMarkAsNonPreActivatedModal"
      ></modal-confirm>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import ActivateInstantCardAction from '../actions/ActivateInstantCardAction';
import PauseInstantCardAction from '../actions/PauseInstantCardAction';
import ResumeInstantCardAction from '../actions/ResumeInstantCardAction';
import DestroyInstantCardAction from '../actions/DestroyInstantCardAction';
import { mapGetters } from 'vuex';

export default {
  name: 'card-information',
  components: { ActivateInstantCardAction, PauseInstantCardAction, ResumeInstantCardAction, DestroyInstantCardAction },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      showActivateCardModal: false,
      showPauseCardModal: false,
      showResumeCardModal: false,
      showDestroyCardModal: false,
      showPreActivateModal: false,
      showMarkAsNonPreActivatedModal: false,
      actions: [
        {
          slotName: 'ActivateInstantCardModal',
          name: this.$t('instant_card_cards.details.card_information_section.activate_card'),
          action: () => {
            this.openActivateCardModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showActivateCardFeature();
          },
        },
        {
          slotName: 'PreActivateModal',
          name: this.$t('instant_card_cards.details.card_information_section.pre_activate'),
          action: () => {
            this.openPreActivateCardModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showPreActivateFeature() || this.card.activation_enabled;
          },
        },
        {
          slotName: 'MarkAsNonPreActivatedModal',
          name: this.$t('instant_card_cards.details.card_information_section.mark_as_non_pre_activated'),
          action: () => {
            this.openMarkAsNonPreActivatedCardModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showMarkAsNonPreActivatedFeature() || !this.card.activation_enabled;
          },
        },
        {
          slotName: 'PauseInstantCardModal',
          name: this.$t('instant_card_cards.details.card_information_section.pause_card'),
          action: () => {
            this.openPauseCardModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showPauseCardFeature();
          },
        },
        {
          slotName: 'ResumeInstantCardModal',
          name: this.$t('instant_card_cards.details.card_information_section.resume_card'),
          action: () => {
            this.openResumeCardModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showResumeCardFeature();
          },
        },
        {
          slotName: 'DestroyInstantCardModal',
          name: this.$t('instant_card_cards.details.card_information_section.destroy_card'),
          action: () => {
            this.openDestroyCardModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showDestroyCardFeature();
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('instantCardCard', ['card']),
  },
  methods: {
    showActionBtn() {
      return true; // TODO set
    },
    openActivateCardModal() {
      this.showActivateCardModal = true;
    },
    closeActivateCardModal() {
      this.showActivateCardModal = false;
    },
    openPreActivateCardModal() {
      this.showPreActivateModal = true;
    },
    closePreActivateModal() {
      this.showPreActivateModal = false;
    },
    openMarkAsNonPreActivatedCardModal() {
      this.showMarkAsNonPreActivatedModal = true;
    },
    closeMarkAsNonPreActivatedModal() {
      this.showMarkAsNonPreActivatedModal = false;
    },
    openPauseCardModal() {
      this.showPauseCardModal = true;
    },
    closePauseCardModal() {
      this.showPauseCardModal = false;
    },
    openResumeCardModal() {
      this.showResumeCardModal = true;
    },
    closeResumeCardModal() {
      this.showResumeCardModal = false;
    },
    openDestroyCardModal() {
      this.showDestroyCardModal = true;
    },
    closeDestroyCardModal() {
      this.showDestroyCardModal = false;
    },
    async activateCardSuccess() {
      // this.showActivateCardModal = false;
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_cards.details.card_information_section.activate_action.success' },
      );
      this.closeActivateCardModal();
    },
    async pauseCardSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_cards.details.card_information_section.pause_action.success' },
      );
      this.closePauseCardModal();
    },
    async resumeCardSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_cards.details.card_information_section.resume_action.success' },
      );
      this.closeResumeCardModal();
    },
    async destroyCardSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_cards.details.card_information_section.destroy_action.success' },
      );
      this.closeDestroyCardModal();
    },
    async markAsNonPreActivated() {
      await this.executeAction(
        {
          action: 'instantCardCard/markAsNonPreActivated',
          name: 'markAsNonPreActivated',
          success: this.markAsNonPreActivatedSuccess,
        },
        { public_token: this.card.public_token },
      );
    },
    async markAsNonPreActivatedSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: `instant_card_cards.details.card_information_section.mark_as_non_pre_activated_success` },
      );
      this.closeMarkAsNonPreActivatedModal();
    },
    async preActivate() {
      await this.executeAction(
        {
          action: 'instantCardCard/preActivate',
          name: 'preActivate',
          success: this.preActivateSuccess,
        },
        { public_token: this.card.public_token },
      );
    },
    async preActivateSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: `instant_card_cards.details.card_information_section.pre_activate_success` },
      );
      this.closePreActivateModal();
    },
    showActivateCardFeature() {
      return this.card.status === 'pending_activation';
    },
    showPauseCardFeature() {
      return this.card.status === 'pending_activation';
    },
    showPreActivateFeature() {
      return this.card.status === 'pending_activation';
    },
    showMarkAsNonPreActivatedFeature() {
      return this.card.status === 'pending_activation';
    },
    showResumeCardFeature() {
      return this.card.status === 'paused';
    },
    showDestroyCardFeature() {
      return this.card.status === 'pending_activation';
    },
  },
};
</script>

<style scoped="true" lang="stylus">
.card-info-public-token-clipboard.clipboard .v-icon  {
  margin-bottom: 0.4em;
}
</style>
