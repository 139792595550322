import Vue from 'vue';
import Router from 'vue-router';
import store from './store/store';

import HomeComponent from './components/Home.vue';

import ApplicationView from '@/components/application/ApplicationView.vue';
import CardDetailView from '@/components/card/CardDetailView.vue';
import WalletDefinitionListView from '@/components/wallet-definition/WalletDefinitionListView.vue';
import WalletDefinitionDetailView from '@/components/wallet-definition/WalletDefinitionDetailView.vue';

import TaskView from '@/components/task/TaskView.vue';
import BankAccountsListView from '@/components/bank_account/BankAccountsListView.vue';
import BankAccountDetailView from '@/components/bank_account/BankAccountDetailView.vue';
import BrandingListView from '@/components/branding/BrandingListView.vue';
import BrandingDetailView from '@/components/branding/BrandingDetailView.vue';
import BusinessListView from '@/components/business/BusinessListView.vue';
import BusinessDetailView from '@/components/business/BusinessDetailView.vue';
import PublishersListView from '@/components/publishers/PublishersListView.vue';
import PublishersDetailView from '@/components/publishers/PublishersDetailView.vue';
import StoreListView from '@/components/store/StoreListView.vue';
import StoreDetailView from '@/components/store/StoreDetailView.vue';
import InventoryListView from '@/components/inventory/InventoryListView.vue';
import InventoryDetailView from '@/components/inventory/InventoryDetailView.vue';
import FranchiseListView from '@/components/franchise/FranchiseListView.vue';
import FranchiseDetailView from '@/components/franchise/FranchiseDetailView.vue';
import DestinationListView from '@/components/destination/DestinationListView.vue';
import DestinationDetailView from '@/components/destination/DestinationDetailView.vue';
import OffersListView from '@/components/offers/OffersListView.vue';
import OffersDetailView from '@/components/offers/OffersDetailView.vue';
import VoucherListView from '@/components/voucher/VoucherListView.vue';
import VoucherDetailsView from '@/components/voucher/VoucherDetailsView.vue';
import UserListView from '@/components/user/UserListView.vue';
import UserDetailView from '@/components/user/UserDetailView.vue';
import UserPurseDetailView from '@/components/purse/UserPurseDetailView.vue';
import ActivationCodeDetailView from '@/components/activation_code/ActivationCodeDetailView.vue';
import ActivationCodeBatchDetailView from '@/components/activation_code_batch/ActivationCodeBatchDetailView.vue';
import TransactionsReportView from '@/components/dashboards/TransactionsReportView.vue';
import DebitsAndCreditsReportView from '@/components/dashboards/DebitsAndCreditsReportView.vue';
import CardActivitiesReportView from '@/components/dashboards/CardActivitiesReportView.vue';
import CardApplicationsReportView from '@/components/dashboards/CardApplicationsReportView.vue';
import ActivationCodesReportView from '@/components/dashboards/ActivationCodesReportView.vue';
import VouchersOperationsReportView from '@/components/dashboards/VouchersOperationsReportView.vue';
import VouchersReportView from '@/components/dashboards/VouchersReportView.vue';
import BusinessSuitePadsReportView from '@/components/dashboards/BusinessSuitePadsReportView.vue';
import BusinessSuitePromoterView from '@/components/dashboards/BusinessSuitePromoterView.vue';
import BusinessSuiteMembersView from '@/components/dashboards/BusinessSuiteMembersView.vue';
import BusinessSuiteBalancesView from '@/components/dashboards/BusinessSuiteBalancesView.vue';
import BusinessSuiteEmployerReportView from '@/components/dashboards/BusinessSuiteEmployerReportView.vue';
import BusinessSuiteTransactionsView from '@/components/dashboards/BusinessSuiteTransactionsView.vue';
import BusinessSuiteCorporateProgramBusinessesView from '@/components/dashboards/BusinessSuiteCorporateProgramBusinessesView.vue';
import BusinessSuiteCorporateProgramOrdersView from '@/components/dashboards/BusinessSuiteCorporateProgramOrdersView.vue';
import InstantCardProjectsListView from '@/components/instant_card_project/InstantCardProjectsListView.vue';
import InstantCardProjectDetailView from '@/components/instant_card_project/InstantCardProjectDetailView.vue';
import InstantCardBatchesListView from '@/components/instant_card_batch/InstantCardBatchesListView.vue';
import InstantCardBatchDetailView from '@/components/instant_card_batch/InstantCardBatchDetailView.vue';
import InstantCardsListView from '@/components/instant_card/InstantCardsListView.vue';
import InstantCardDetailView from '@/components/instant_card/InstantCardDetailView.vue';
import SpaceDetailView from '@/components/space/SpaceDetailView.vue';
import SpaceListView from '@/components/space/SpaceListView.vue';
import ClerkDetailView from '@/components/clerk/ClerkDetailView.vue';
import WorkstationDetailView from '@/components/workstation/WorkstationDetailView.vue';
import WorkstationRegistrationDetailView from '@/components/workstation-registration/WorkstationRegistrationDetailView.vue';
import EstablishmentDetailView from '@/components/establishment/EstablishmentDetailView.vue';

import NoAccessView from '@/components/NoAccessView.vue';
import PageNotFoundView from '@/components/PageNotFoundView.vue';

import LanguageSwitcher from './components/header/LanguageSwitcher.vue';

import { registerSystemComponent } from './components/system';

Vue.use(Router);

Vue.component('language-switcher', LanguageSwitcher);

registerSystemComponent(Vue);
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'root',
      component: HomeComponent,
    },
    {
      path: '/no-access',
      name: 'no-access',
      component: NoAccessView,
    },
    {
      path: '/application/:applicationId?',
      name: 'application',
      component: ApplicationView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_application',
        title: 'Application',
      },
    },
    {
      path: '/card/:cardPublicToken?',
      name: 'card',
      component: CardDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card',
        title: 'Card',
      },
    },
    {
      path: '/wallet-definitions',
      name: 'wallet_definition',
      component: WalletDefinitionListView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_wallet_definition',
        title: 'Wallet Definition',
      },
    },
    {
      path: '/wallet-definitions/:walletDefinitionId',
      name: 'wallet_definition_details',
      component: WalletDefinitionDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Wallet Definition Details',
      },
    },
    {
      path: '/bank-accounts',
      name: 'bank_accounts',
      component: BankAccountsListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Bank Account',
      },
    },
    {
      path: '/bank-accounts/:bankAccountKey',
      name: 'bank_account_details',
      component: BankAccountDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Bank Account',
      },
    },
    {
      path: '/task',
      name: 'task',
      component: TaskView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_task',
        title: 'Task',
      },
    },
    {
      path: '/inventories',
      name: 'inventories',
      component: InventoryListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new?
        title: 'Inventory',
      },
    },
    {
      path: '/inventory/:inventoryId',
      name: 'inventory_details',
      component: InventoryDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Inventory',
      },
    },
    {
      path: '/franchises',
      name: 'franchises',
      component: FranchiseListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new?
        title: 'Franchise',
      },
    },
    {
      path: '/franchise/:franchiseId',
      name: 'franchise_details',
      component: FranchiseDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Franchise',
      },
    },
    {
      path: '/destinations',
      name: 'destinations',
      component: DestinationListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new?
        title: 'Destination',
      },
    },
    {
      path: '/destination/:destinationId',
      name: 'destination_details',
      component: DestinationDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Destination',
      },
    },
    {
      path: '/publishers',
      name: 'publishers',
      component: PublishersListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new?
        title: 'Publishers',
      },
    },
    {
      path: '/publishers/:publishersId',
      name: 'publishers_details',
      component: PublishersDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new?
        title: 'Publishers',
      },
    },
    {
      path: '/closed-loops',
      name: 'closed_loops',
      component: PublishersListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new?
        title: 'Closed Loops',
      },
    },
    {
      path: '/closed-loops/:publishersId',
      name: 'closed_loop_details',
      component: PublishersDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new?
        title: 'Closed Loops',
      },
    },
    {
      path: '/activation-codes/:activationCodeId',
      name: 'activation_code_details',
      component: ActivationCodeDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new?
        title: 'Activation Code',
      },
    },
    {
      path: '/activation-code-batches/:activationCodeBatchId',
      name: 'activation_code_batch_details',
      component: ActivationCodeBatchDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new?
        title: 'Activation Code Batch',
      },
    },
    {
      path: '/offers',
      name: 'offers',
      component: OffersListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new?
        title: 'Offers',
      },
    },
    {
      path: '/offers/:offerId',
      name: 'offers_details',
      component: OffersDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Offer',
      },
    },
    {
      path: '/vouchers',
      name: 'vouchers',
      component: VoucherListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new?
        title: 'Vouchers',
      },
    },
    {
      path: '/vouchers/:voucherId',
      name: 'voucher_details',
      component: VoucherDetailsView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new?
        title: 'Voucher',
      },
    },
    {
      path: '/brandings',
      name: 'brandings',
      component: BrandingListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Brandings',
      },
    },
    {
      path: '/branding/:brandingId',
      name: 'branding_details',
      component: BrandingDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Branding',
      },
    },
    {
      path: '/spaces',
      name: 'spaces',
      component: SpaceListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Spaces',
      },
    },
    {
      path: '/spaces/:spaceId',
      name: 'space_details',
      component: SpaceDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Space',
      },
    },
    {
      path: '/spaces/:spaceId/clerks/:clerkId',
      name: 'clerk_details',
      component: ClerkDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Clerk',
      },
    },
    {
      path: '/spaces/:spaceId/workstations/:workstationId',
      name: 'workstation_details',
      component: WorkstationDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Workstation',
      },
    },
    {
      path: '/spaces/:spaceId/workstation/:workstationId/workstation-registration-requests/:workstationRegistrationRequestId',
      name: 'workstation_registration_request_details',
      component: WorkstationRegistrationDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'WorkstationRegistrationRequest',
      },
    },
    {
      path: '/spaces/:spaceId/establishments/:establishmentId',
      name: 'establishment_details',
      component: EstablishmentDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Establishment',
      },
    },
    {
      path: '/businesses',
      name: 'businesses',
      component: BusinessListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Businesses',
      },
    },
    {
      path: '/business/:businessId',
      name: 'business_details',
      component: BusinessDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Business',
      },
    },
    {
      path: '/stores',
      name: 'stores',
      component: StoreListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Stores',
      },
    },
    {
      path: '/store/:storeId',
      name: 'store_details',
      component: StoreDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Store',
      },
    },
    {
      path: '/users',
      name: 'users',
      component: UserListView,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'Users',
      },
    },
    {
      path: '/user/:userId',
      name: 'user_details',
      component: UserDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'User',
      },
    },
    {
      path: '/user/:userId/purses/:purseId',
      name: 'user_purses_details',
      component: UserPurseDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'User Purse',
      },
    },
    {
      path: '/instant-card-projects',
      name: 'instant_card_projects',
      component: InstantCardProjectsListView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'InstantCardProjects',
      },
    },
    {
      path: '/instant-card-projects/:projectId',
      name: 'instant_card_project_details',
      component: InstantCardProjectDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'InstantCardProject',
      },
    },
    {
      path: '/instant-card-batches',
      name: 'instant_card_batches',
      component: InstantCardBatchesListView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'InstantCardBatches',
      },
    },
    {
      path: '/instant-card-batches/:batchId',
      name: 'instant_card_batch_details',
      component: InstantCardBatchDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'InstantCardBatch',
      },
    },
    {
      path: '/instant-cards',
      name: 'instant_cards',
      component: InstantCardsListView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'InstantCards',
      },
    },
    {
      path: '/instant-card/:cardId',
      name: 'instant_card_details',
      component: InstantCardDetailView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_offer', // Todo create new
        title: 'InstantCard',
      },
    },
    {
      path: '/dashboards/transactions',
      name: 'transactions_dashboard',
      component: TransactionsReportView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Transactions',
      },
    },
    {
      path: '/dashboards/debits-credits',
      name: 'debits_credits_dashboard',
      component: DebitsAndCreditsReportView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Debits and Credits',
      },
    },
    {
      path: '/dashboards/card-activities',
      name: 'card_activities_dashboard',
      component: CardActivitiesReportView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Card Activities',
      },
    },
    {
      path: '/dashboards/card-applications',
      name: 'card_applications_dashboard',
      component: CardApplicationsReportView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Card Applications',
      },
    },
    {
      path: '/dashboards/activation-codes',
      name: 'activation_codes_dashboard',
      component: ActivationCodesReportView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Activation Codes',
      },
    },
    {
      path: '/dashboards/vouchers',
      name: 'vouchers_dashboard',
      component: VouchersReportView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Vouchers',
      },
    },
    {
      path: '/dashboards/vouchers-operations',
      name: 'vouchers_operations_dashboard',
      component: VouchersOperationsReportView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'vouchers Operations',
      },
    },
    {
      path: '/dashboards/business-suite/corporate-program-businesses',
      name: 'business_suite_corporate_program_businesses_dashboard',
      component: BusinessSuiteCorporateProgramBusinessesView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Business Suite Corporate Program - Businesses',
      },
    },
    {
      path: '/dashboards/business-suite/corporate-program-orders',
      name: 'business_suite_corporate_program_orders_dashboard',
      component: BusinessSuiteCorporateProgramOrdersView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Business Suite Corporate Program - Orders',
      },
    },
    {
      path: '/dashboards/business-suite/promoter',
      name: 'business_suite_promoter_dashboard',
      component: BusinessSuitePromoterView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Business Suite Promoter',
      },
    },
    {
      path: '/dashboards/business-suite/members',
      name: 'business_suite_members_dashboard',
      component: BusinessSuiteMembersView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Business Suite Members',
      },
    },
    {
      path: '/dashboards/business-suite/balances',
      name: 'business_suite_balances_dashboard',
      component: BusinessSuiteBalancesView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Business Suite Balances',
      },
    },
    {
      path: '/dashboards/business-suite/employer',
      name: 'business_suite_employer_dashboard',
      component: BusinessSuiteEmployerReportView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card',
        title: 'Business Suite Employer',
      },
    },
    {
      path: '/dashboards/business-suite/transactions',
      name: 'business_suite_transactions_dashboard',
      component: BusinessSuiteTransactionsView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Business Suite Transactions',
      },
    },
    {
      path: '/dashboards/business-suite/pads',
      name: 'business_suite_pads_dashboard',
      component: BusinessSuitePadsReportView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_card', // Todo create new
        title: 'Business Suite PADs',
      },
    },
    {
      path: '/not-found',
      name: 'notFound',
      component: PageNotFoundView,
    },
    {
      path: '*',
      component: PageNotFoundView,
    },
  ],
});

const onAuthRequired = async (to, from, next) => {
  // NOTE: Clear only if change route
  if (to.name !== from.name && to.name !== 'login') {
    await store.dispatch('ui/clearAllError');
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !(await Vue.prototype.$auth.isAuthenticated())) {
    Vue.prototype.$cookies.setRedirectPathCookie(to.fullPath);
    next({ name: 'login' });
  } else if (to.path === '/login' && (await Vue.prototype.$auth.isAuthenticated())) {
    next({ name: 'root' });
  } else if (to.matched.some((record) => record.meta.requiresPermission)) {
    const canAccess = store.getters['security/hasPermission'](to.meta.requiresPermission);
    if (!canAccess) {
      next({ name: 'notFound' });
    } else {
      next();
    }
  } else {
    next();
  }
};

router.beforeEach(onAuthRequired);

export default router;
