<template>
  <card-modal
    icon=""
    :title="$t('instant_card_batches.update_action.title')"
    context="updateBatch"
    @submit="submit"
    @cancel="cancel"
    cancel_text="instant_card_batches.update_action.button_cancel"
    submit_text="instant_card_batches.update_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <label-text :label="`${$t('instant_card_batches.update_action.id')}`" :value="batch.id" />
        <v-text-field
          v-model="currentBatch.name"
          :label="$t('instant_card_batches.update_action.name') + '*'"
          name="name"
          maxlength="100"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          data-vv-name="name"
          :data-vv-as="$t('instant_card_batches.update_action.name')"
          data-test="update-batch--name"
          required
        ></v-text-field>
        <h-autocomplete
          @itemsFetched="instantCardProjectUpdated"
          v-model="currentBatch.instant_card_project_id"
          :label="$t('instant_card_batches.create_action.project')"
          v-validate="'max:50'"
          :error-messages="errors.collect('project')"
          name="project"
          data-vv-name="project"
          data-vv-as="project"
          search-action="instantCardProject/autocompleteSearch"
          data-test="update_batch--project"
          no-filter
          clearable
        >
        </h-autocomplete>
        <v-text-field
          v-model="currentBatch.quantity"
          :label="$t('instant_card_batches.update_action.quantity') + '*'"
          name="quantity"
          v-validate="'required|max_value:5000|min_value:2'"
          :error-messages="errors.collect('quantity')"
          data-vv-name="quantity"
          :data-vv-as="$t('instant_card_batches.update_action.quantity')"
          data-test="update-batch--quantity"
          required
        ></v-text-field>
        <v-select
          v-model="currentBatch.card_package_id"
          :label="$t(`instant_card_batches.create_action.card_package`) + '*'"
          name="cardPackage"
          :rules="selectCardPackageRules()"
          :items="cardPackageOptions"
          item-text="text"
          item-value="id"
          data-test="update_batch--card-package"
        ></v-select>
        <v-checkbox
          name="pre_activation_required"
          v-model="currentBatch.pre_activation_required"
          :label="$t(`instant_card_batches.update_action.pre_activation_required`)"
          :error-messages="errors.collect('pre_activation_required')"
          data-vv-name="pre_activation_required"
          :data-vv-as="$t(`instant_card_batches.update_action.pre_activation_required`)"
          type="checkbox"
          :disabled="preActivationRequiredDisabled"
        ></v-checkbox>
        <h-autocomplete
          v-model="currentBatch.business_id"
          :label="$t('instant_card_batches.update_action.business') + '*'"
          v-validate="'required'"
          :error-messages="errors.collect('business')"
          name="business"
          data-vv-name="business"
          data-vv-as="business"
          search-action="business/autocompleteSearch"
          data-test="update-batch--business"
          no-filter
        >
        </h-autocomplete>
        <a v-if="currentBatch.business_id" @click="fillAddressFieldsFromBusiness">{{
          $t('instant_card_batches.create_action.business_address_note')
        }}</a>
        <h-autocomplete
          v-model="currentBatch.establishment_id"
          :label="$t('instant_card_batches.create_action.establishment')"
          :error-messages="errors.collect('establishment_id')"
          :alternate-text="getEstablishmentLabel"
          alternate-id="business_id"
          name="establishment_id"
          data-vv-name="establishment_id"
          data-vv-as="establishment_id"
          search-action="space/autocompleteSearchSpacePartnerEstablishments"
          data-test="form-instant-card-batches--establishment"
          no-filter
          clearable
          @selectedValue="updateEstablishment"
        >
        </h-autocomplete>
        <a v-if="currentBatch.establishment_business_id" @click="fillAddressFieldsFromEstablishment">{{
          $t('instant_card_batches.create_action.establishment_address_note')
        }}</a>

        <h4>{{ $t('instant_card_batches.update_action.address_title') }}</h4>
        <v-text-field
          name="street"
          v-model="currentBatch.delivery_address.street"
          v-validate="'required|max:50'"
          :label="$t(`instant_card_batches.update_action.address.street`) + '*'"
          :error-messages="errors.collect('street')"
          data-vv-name="street"
          :data-vv-as="$t(`instant_card_batches.update_action.address.street`)"
          maxlength="50"
          counter
          data-test="update_batch--street"
          required
        ></v-text-field>
        <v-text-field
          name="city"
          v-model="currentBatch.delivery_address.city"
          v-validate="'required|max:50'"
          :label="$t(`instant_card_batches.update_action.address.city`) + '*'"
          :error-messages="errors.collect('city')"
          data-vv-name="city"
          :data-vv-as="$t(`instant_card_batches.update_action.address.city`)"
          counter
          maxlength="50"
          data-test="update_batch--city"
          required
        ></v-text-field>
        <v-select
          name="state"
          v-model="currentBatch.delivery_address.state"
          v-validate="'required'"
          :error-messages="errors.collect('state')"
          data-vv-name="state"
          :data-vv-as="$t(`instant_card_batches.update_action.address.state`)"
          :items="stateOptions"
          item-text="name"
          item-value="abbr"
          :label="$t(`instant_card_batches.update_action.address.state`) + '*'"
          data-test="update_batch--state"
        ></v-select>
        <v-select
          name="country"
          v-model="currentBatch.delivery_address.country"
          v-validate="'required'"
          :error-messages="errors.collect('country')"
          data-vv-name="country"
          :data-vv-as="$t(`instant_card_batches.update_action.address.country`)"
          :items="countryOptions"
          item-text="name"
          item-value="abbr"
          :label="$t(`instant_card_batches.update_action.address.country`) + '*'"
          data-test="update_batch--country"
        ></v-select>
        <v-text-field
          name="zip"
          v-mask-zip
          v-model="currentBatch.delivery_address.zip"
          v-validate="{ required: true, regex: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/ }"
          :label="$t(`instant_card_batches.update_action.address.zip`) + '*'"
          :error-messages="errors.collect('zip')"
          data-vv-name="zip"
          :data-vv-as="$t(`instant_card_batches.update_action.address.zip`)"
          data-test="update_batch--zip"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';
import _find from 'lodash/find';

export default {
  name: 'update-batch-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    batch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      instantCardProject: null,
      currentBatch: { delivery_address: { country: 'CA', state: 'QC' } },
      countryOptions: [{ name: this.$t('country.canada'), abbr: 'CA' }],
      stateOptions: [
        { name: this.$t('state.alberta'), abbr: 'AB' },
        { name: this.$t('state.british_columbia'), abbr: 'BC' },
        { name: this.$t('state.manitoba'), abbr: 'MB' },
        { name: this.$t('state.new_brunswick'), abbr: 'NB' },
        { name: this.$t('state.newfoundland_labrador'), abbr: 'NL' },
        { name: this.$t('state.nova_scotia'), abbr: 'NS' },
        { name: this.$t('state.northwest_territories'), abbr: 'NT' },
        { name: this.$t('state.nunavut'), abbr: 'NU' },
        { name: this.$t('state.ontario'), abbr: 'ON' },
        { name: this.$t('state.prince_edward_island'), abbr: 'PE' },
        { name: this.$t('state.quebec'), abbr: 'QC' },
        { name: this.$t('state.saskatchewan'), abbr: 'SK' },
        { name: this.$t('state.yukon'), abbr: 'YT' },
      ],
      cardPackageOptions: [],
    };
  },
  async created() {
    this.currentBatch = Object.assign(
      {
        delivery_address: { country: 'CA', state: 'QC' },
      },
      _.cloneDeep(this.batch),
    );

    if (this.currentBatch.card_package_id) {
      await this.getCardPackage(this.currentBatch.card_package_id);
    }

    await this.fetchCardPackages();
  },
  watch: {
    cardPackages(val) {
      if (val) {
        this.cardPackageOptions = this.mapCardPackages(this.cardPackages);
      }
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('context', ['cardPackages', 'cardPackage']),
    preActivationRequiredDisabled() {
      const type = _.get(this.instantCardProject, 'type', null);
      return type === 'blank' || type === 'reward';
    },
  },
  methods: {
    clear() {
      this.currentBatch = { delivery_address: { country: 'CA', state: 'QC' } };
      this.$validator.reset();
    },
    selectCardPackageRules() {
      return [(v) => !!v || this.$t('validation.select.card_package')];
    },
    instantCardProjectUpdated(instantCardProjects) {
      const instantCardProject = _find(instantCardProjects, (i) => i.id === this.currentBatch.instant_card_project_id);

      this.instantCardProject = instantCardProject;

      this.currentBatch.pre_activation_required = _.get(instantCardProject, 'type') === 'blank';
    },
    async fetchCardPackages() {
      await this.executeAction(
        { action: 'context/listCardPackages' },
        { page: 1, limit: 100, params: { card_program_type: 'instant', status: 'active', bulk: true } },
      );
    },
    async getCardPackage(id) {
      await this.executeAction({ action: 'context/getCardPackage' }, id);
    },
    mapCardPackages() {
      return _.map(this.cardPackages, (cardPackage) => {
        cardPackage.text = `${cardPackage.name} (${cardPackage.id})`;
        return cardPackage;
      });
    },
    async fillAddressFieldsFromEstablishment() {
      await this.fillAddressFields(this.currentBatch.establishment_business_id);
    },
    async fillAddressFieldsFromBusiness() {
      await this.fillAddressFields(this.currentBatch.business_id);
    },
    async fillAddressFields(business_id) {
      const business = await this.executeAction(
        {
          action: 'business/getBusiness',
        },
        business_id,
      );

      this.currentBatch.delivery_address.street = business.address.street;
      this.currentBatch.delivery_address.city = business.address.city;
      this.currentBatch.delivery_address.state = business.address.state;
      this.currentBatch.delivery_address.country = business.address.country;
      this.currentBatch.delivery_address.zip = business.address.zip;
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      const payload = Object.assign(
        {},
        _.omit(this.currentBatch, ['establishment_partner_id', 'establishment_business_id', 'establishment_business_name', 'loaded', 'cancelled']),
      );

      if (!payload.instant_card_project_id) {
        payload.instant_card_project_id = null;
      }
      if (!payload.establishment_id) {
        payload.establishment_id = null;
      }
      return payload;
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardBatch/updateBatch',
            name: 'updateBatch',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    getEstablishmentLabel(establishment) {
      return `${establishment.partner_id} - ${establishment.business_name}`;
    },
    updateEstablishment(establishment) {
      this.currentBatch.establishment_business_id = establishment ? establishment.business_id : '';
    },
  },
};
</script>
