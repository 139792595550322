import Vue from 'vue';

const defaultPage = 1;
const paginationLimit = 20;
const inventoryMouvementsPaginationLimit = 20;
const inventoryOffersPaginationLimit = 20;
const inventoryNotesPaginationLimit = 20;

export default {
  namespaced: true,
  state: {
    inventories: [],
    inventoryMouvements: [],
    inventory: {},
    inventory_notes: [],
    list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
      keyword: '',
      partner_id: '',
      business_id: '',
    },
    inventory_notes_list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    inventoryMouvementsPageNumber: defaultPage,
    inventoryMouvementsPageCount: 0,
    inventoryMouvementsItemCount: 0,
    inventoryOffers: [],
    inventoryOffersPageNumber: defaultPage,
    inventoryOffersPageCount: 0,
    inventoryOffersItemCount: 0,
  },
  getters: {
    inventories: (state) => state.inventories,
    inventoryMouvements: (state) => state.inventoryMouvements,
    inventory: (state) => state.inventory,
    types: (state) => state.types,
    categories: (state) => state.categories,
    inventoryMouvementsPageCount: (state) => state.inventoryMouvementsPageCount,
    inventoryMouvementsItemCount: (state) => state.inventoryMouvementsItemCount,
    inventoryMouvementsPageNumber: (state) => state.inventoryMouvementsPageNumber,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
    listKeyword: (state) => state.list.keyword,
    listPartnerId: (state) => state.list.partner_id,
    listBusinessId: (state) => state.list.business_id,
    inventoryOffers: (state) => state.inventoryOffers,
    inventoryOffersPageCount: (state) => state.inventoryOffersPageCount,
    inventoryOffersItemCount: (state) => state.inventoryOffersItemCount,
    inventoryOffersPageNumber: (state) => state.inventoryOffersPageNumber,
    inventoryNotes: (state) => state.inventory_notes,
    listInventoryNotesPageCount: (state) => state.inventory_notes_list.pageCount,
    listInventoryNotesItemCount: (state) => state.inventory_notes_list.itemCount,
    listInventoryNotesPageNumber: (state) => state.inventory_notes_list.pageNumber,
  },
  mutations: {
    inventories(state, inventories) {
      state.inventories = inventories;
    },
    inventoryMouvements(state, inventoryMouvements) {
      state.inventoryMouvements = inventoryMouvements;
    },
    inventory(state, inventory) {
      if (!inventory) {
        inventory = {};
      }
      state.inventory = inventory;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPageNumber(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listPartnerId(state, partner_id) {
      state.list.partner_id = partner_id;
    },
    listBusinessId(state, business_id) {
      state.list.business_id = business_id;
    },

    inventoryMouvementsPageCount(state, pageCount) {
      state.inventoryMouvementsPageCount = pageCount;
    },
    inventoryMouvementsItemCount(state, itemCount) {
      state.inventoryMouvementsItemCount = itemCount;
    },
    inventoryMouvementsPage(state, page) {
      state.inventoryMouvementsPageNumber = page;
    },

    inventoryOffers(state, inventoryOffers) {
      state.inventoryOffers = inventoryOffers;
    },
    inventoryOffersPageCount(state, pageCount) {
      state.inventoryOffersPageCount = pageCount;
    },
    inventoryOffersItemCount(state, itemCount) {
      state.inventoryOffersItemCount = itemCount;
    },
    inventoryOffersPage(state, page) {
      state.inventoryOffersPageNumber = page;
    },

    inventoryNotes(state, notes) {
      state.inventory_notes = notes;
    },
    listInventoryNotesPageCount(state, pageCount) {
      state.inventory_notes_list.pageCount = pageCount;
    },
    listInventoryNotesItemCount(state, itemCount) {
      state.inventory_notes_list.itemCount = itemCount;
    },
    listInventoryNotesPage(state, page) {
      state.inventory_notes_list.pageNumber = page;
    },
  },
  actions: {
    async autocompleteSearch({}, { page, limit, keyword }) {
      const inventoryService = Vue.prototype.$services.inventory;

      return inventoryService.searchInventories(page, limit, keyword);
    },
    async clearSearchInventories({ commit }) {
      commit('inventories', []);
    },
    async createInventory({ commit }, params) {
      const inventoryService = Vue.prototype.$services.inventory;
      return inventoryService.createInventory(params);
    },
    async listInventories({ state, commit }, { keyword, partner_id, business_id, page, limit } = {}) {
      const inventoryService = Vue.prototype.$services.inventory;

      const thePage = page || state.list.pageNumber || 1;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';
      const thePartnerId = partner_id || '';
      const theBusinessId = business_id || '';

      const listResults = await inventoryService.listInventories(theKeyword, thePartnerId, theBusinessId, thePage, theLimit);

      commit('inventories', listResults.items);
      commit('listPageNumber', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('listKeyword', theKeyword);
      commit('listPartnerId', thePartnerId);
      commit('listBusinessId', theBusinessId);
    },
    async getInventory({ commit, dispatch }, id) {
      const inventoryService = Vue.prototype.$services.inventory;
      const inventory = await inventoryService.getInventory(id);

      commit('inventory', inventory);

      return inventory;
    },
    async createInventoryNote({ dispatch }, { inventory_id, note }) {
      const inventoryService = Vue.prototype.$services.inventory;
      await inventoryService.createInventoryNote(inventory_id, { note });
      await dispatch('listInventoryNotes', { inventory_id });
    },
    async deleteInventoryNote({ dispatch }, { inventory_id, note_id, reason }) {
      const inventoryService = Vue.prototype.$services.inventory;
      await inventoryService.deleteInventoryNote(inventory_id, note_id, { reason });
      await dispatch('listInventoryNotes', { inventory_id });
    },
    async listInventoryNotes({ state, commit }, { inventory_id, page, limit } = {}) {
      const inventoryService = Vue.prototype.$services.inventory;
      const thePage = page || state.inventory_notes_list.pageNumber;
      const theLimit = limit || inventoryNotesPaginationLimit;
      const theInventoryId = inventory_id || '';

      const listResults = await inventoryService.listInventoryNotes(thePage, theLimit, theInventoryId);

      commit('listInventoryNotesPage', thePage);
      commit('listInventoryNotesPageCount', listResults.page_count);
      commit('listInventoryNotesItemCount', listResults.item_count);
      commit('inventoryNotes', listResults.items);
    },
    async listInventoryMouvements({ commit }, { id, types, page, limit }) {
      const thePage = page || defaultPage;
      const theLimit = limit || inventoryMouvementsPaginationLimit;
      const inventoryService = Vue.prototype.$services.inventory;
      const result = await inventoryService.listInventoryMouvements(id, types, thePage, theLimit);

      commit('inventoryMouvements', result.items);
      commit('inventoryMouvementsPage', thePage);
      commit('inventoryMouvementsPageCount', result.page_count);
      commit('inventoryMouvementsItemCount', result.item_count);
    },
    async listInventoryOffers({ commit }, { id, page }) {
      const inventoryService = Vue.prototype.$services.inventory;
      const thePage = page || defaultPage;
      const result = await inventoryService.listInventoryOffers(id, 1, inventoryOffersPaginationLimit);
      const inventoryOffers = result.items;

      commit('inventoryOffers', inventoryOffers);
      commit('inventoryOffersPage', thePage);
      commit('inventoryOffersPageCount', result.page_count);
      commit('inventoryOffersItemCount', result.item_count);
    },
    async changeInventoryOffersPage({ commit, state }, { id, page }) {
      commit('inventoryOffersPage', page);

      const inventoryService = Vue.prototype.$services.inventory;
      const limit = inventoryOffersPaginationLimit;

      const inventoryOffersResult = await inventoryService.listInventoryOffers(id, page, limit);

      commit('inventoryOffersPageCount', inventoryOffersResult.page_count);
      commit('inventoryOffers', inventoryOffersResult.items);
    },
    async updateInventory({ dispatch }, params) {
      const inventoryService = Vue.prototype.$services.inventory;
      const { id, ...payload } = params;
      const result = await inventoryService.updateInventory(id, payload);
      await dispatch('getInventory', id);
      return result;
    },
    async adjustInventory({ dispatch }, params) {
      const inventoryService = Vue.prototype.$services.inventory;
      const { id, ...payload } = params;
      const result = await inventoryService.adjustInventory(id, payload);
      await dispatch('getInventory', id);
      return result;
    },
    async linkBusinessToInventory({ dispatch }, { id, ...payload }) {
      const inventoryService = Vue.prototype.$services.inventory;
      const result = await inventoryService.linkBusinessToInventory(id, payload);
      await dispatch('getInventory', id);
      return result;
    },
    async unlinkBusinessFromInventory({ dispatch }, { id }) {
      const inventoryService = Vue.prototype.$services.inventory;
      const result = await inventoryService.unlinkBusinessFromInventory(id);
      await dispatch('getInventory', id);
      return result;
    },
    async replaceBusinessInInventory({ dispatch }, { id, ...payload }) {
      const inventoryService = Vue.prototype.$services.inventory;
      await inventoryService.unlinkBusinessFromInventory(id);
      const result = await inventoryService.linkBusinessToInventory(id, payload);
      await dispatch('getInventory', id);
      return result;
    },
  },
};
